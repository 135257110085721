import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import cssLogo from "../../images/css-logo.svg";
import Footer from "../Footer";
import { NavigationPath } from "../../utils";

const PurchaseTerms = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-center border-b-2 border-custom-pink-900 h-[80px] 2xl:h-auto">
        <img src={cssLogo} alt="cssLogo" className="py-2" />
      </div>

      <div className="py-10  flex-grow">
        <div className="flex flex-col gap-5 md:text-[24px] lg:text-[28px] xl:text-[16px] 2xl:text-[26px]">
          <h1 className="text-center text-[36px] 2xl:text-[50px] font-extrabold text-custom-gray-800 mb-2">
            Uslovi kupovine
          </h1>
          <div className="w-4/5 m-auto">
            <div className="mb-5">
              <p>
                Ovim se uslovima utvrđuje postupak naručivanja, plaćanja,
                isporuke te reklamacija proizvoda koji su ponuđeni na ovim
                stranicama.
              </p>
              <p>
                Internet stranice Udruženja e-Transformacija mogu se koristiti
                za Vašu privatnu upotrebu bez ikakvih naknada za korištenje, a
                prema sljedećim uslovima i pravilima.
              </p>
              <p>
                Prodavac je Udruženje e-Transformacija, a kupac je posjetilac
                ovih stranica koji popuni elektronsku narudžbu, pošalje je
                prodavcu te izvrši plaćanje putem kreditnih kartica ili na
                uplatom na žiro račun na osnovu predračuna.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-1">NARUČIVANJE</div>
              <p>
                Kupac naručuje proizvod (kotizaciju za učešće na konferenciji
                CyberSecuritySummit) putem elektronskog obrasca narudžbe.
              </p>
              <p>
                Kupcem se smatra svaka osoba koja elektronski naruči barem jedan
                proizvod, popuni tražene podatke i pošalje narudžbu.
              </p>
              <p>
                Sve cijene izražene su u bosanskohercegovačkoj valuti -
                konvertibilnoj marci (BAM).{" "}
              </p>
              <p>
                Proizvod se naručuje elektronskim putem, odabirom željenog
                paketa te klikom na dugme Registracija.
              </p>
              <p>
                Proizvod se smatra naručenim u trenutku kada kupac odabere te
                potvrdi način plaćanja.
              </p>
            </div>

            <div className="mb-5">
              <div className="font-semibold mb-1">PLAĆANJE</div>
              <p>
                Naručeni proizvodi ili usluge plaćaju se online jednom od
                kreditnih kartica: Mastercard, Maestro ili Visa.
              </p>
            </div>

            <div className="mb-5">
              <div className="font-semibold mb-1">ISPORUKA</div>
              <p>
                Nakon što kupac plati proizvod automatski dobija akreditaciju sa
                jedinstvenim QR kodom putem email-a koji je naveo prilikom
                registracije i naručivanja proizvoda. Akreditacija sa QR kodom
                će mu poslužiti kao ulaznica na konferenciju.
              </p>
            </div>

            <div className="mb-5">
              <div className="font-semibold mb-1">OPĆENITO</div>
              <p>
                Udruženje e-Transformacija zadržava pravo na izmjenu ovih uslova
                i pravila. Sve promjene primijenit će se na korištenje
                yescss.eventio.app stranica. Kupac snosi odgovornost za tačnost
                i potpunost unesenih podataka prilikom kupovine.{" "}
              </p>
              <p>
                Usluge koje Vam pruža yes-css.eventio.app Internet prodavaonica
                ne uključuje troškove koje snosite koristeći računarsku opremu i
                usluge za pristup našim stranicama. Udruženje e-Transformacija
                nije odgovorno za troškove telefona, Internet prometa ili bilo
                koje druge troškove do kojih može doći.
              </p>
              <p>
                Iako Vam Udruženje e-Transformacija nastoji dati najbolju moguću
                ponudu usluga, Udruženje e-Transformacija ne može garantovati da
                će usluge na yes-css.eventio.app odgovarati Vašim potrebama.
              </p>
              <p>
                Udruženje e-Transformacija također ne može garantovati da će
                usluga biti bez pogrešaka. Ukoliko dođe do pogreške, molimo Vas
                da je prijavite na broj telefona +387 60 322 4596 ili na e-mail
                hello@e-transformacija.ba kako bismo je otklonili na najbrži
                mogući način.
              </p>
            </div>
          </div>

          <Button
            onClick={() => navigate(NavigationPath.REGISTER_ROUTE)}
            htmlType="button"
            className="w-fit m-auto text-white bg-custom-pink-900 border border-custom-pink-900   h-10 sm:text-[16px] font-bold rounded-[2px] shadow mt-10 xl:mt-6"
          >
            NAZAD NA REGISTRACIJU
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PurchaseTerms;
