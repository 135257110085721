import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import cssLogo from "../../images/css-logo.svg";
import Footer from "../Footer";
import { NavigationPath } from "../../utils";

const PaymentSecurity = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-center border-b-2 border-custom-pink-900 h-[80px] 2xl:h-auto">
        <img src={cssLogo} alt="cssLogo" className="py-2" />
      </div>

      <div className="py-10  flex-grow">
        <div className="flex flex-col gap-5 md:text-[24px] lg:text-[28px] xl:text-[16px] 2xl:text-[26px]">
          <h1 className="text-center text-[36px] 2xl:text-[50px] font-extrabold text-custom-gray-800 mb-2">
            Sigurnost plaćanja
          </h1>
          <div className="w-4/5 m-auto">
            <p className="mb-5">
              Tajnost Vaših podataka je zaštićena i osigurana korištenjem
              posljednje verzije TLS enkripcije. Stranice za naplatu putem
              interneta osigurane su korištenjem Secure Socket Layer (SSL)
              protokola sa 128-bitnom enkripcijom podataka. SSL enkripcija je
              postupak šifriranja podataka radi sprječavanja neovlaštenog
              pristupa prilikom njihovog prijenosa.
            </p>
            <p className="mb-5">
              Time je omogućen siguran prijenos informacija te onemogućen
              nedozvoljen pristup podacima prilikom komunikacije između
              korisnikovog računala i WebPay servisa, te obratno.
            </p>
            <p className="mb-5">
              WebPay servis i financijske ustanove razmjenjuju podatke uporabom
              virtualne privatne mreže (VPN), koja je zaštićena od
              neautoriziranog pristupa.
            </p>
            <p>
              Monri Payment Gateway je certificiran prema PCI DSS Level 1
              sigurnosnom standardu propisanom Visa i Mastercard pravilima.
              Trgovac ne pohranjuje brojeve kreditnih kartica i brojevi nisu
              dostupni neovlaštenim osobama.
            </p>
          </div>

          <Button
            onClick={() => navigate(NavigationPath.REGISTER_ROUTE)}
            htmlType="button"
            className="w-fit m-auto text-white bg-custom-pink-900 border border-custom-pink-900   h-10 sm:text-[16px] font-bold rounded-[2px] shadow mt-10 xl:mt-6"
          >
            NAZAD NA REGISTRACIJU
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PaymentSecurity;
