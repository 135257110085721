import { Action } from "redux";

import { LOGIN, SET_LOADING, SET_LOGGED_USER } from "./types";
import { RegisterFieldType } from "../../../models";

type AppAction = {
  type: "SET_LOGGED_USER" | "SET_LOADING";
  payload: null | RegisterFieldType;
};

const initialState = {
  loggedUser: null,
  loading: false,
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
