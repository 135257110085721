import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../store/Store";
import { logoutUser } from "../Authentication/modules/actions";

const mapStateToProps = (state: AppState) => ({
  state: {
    loggedUser: state.user.loggedUser,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      logoutUser,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
