import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../store/Store";
import { getPackagePricing } from "../Dashboard/modules/actions";
import {
  getSearchedCompanies,
  publicRegisterCompanyAndParticipants,
  publicRegisterParticipants,
} from "../Participants/modules/actions";

const mapStateToProps = (state: AppState) => ({
  state: {
    packagePricing: state.dashboardReducer.packagePricing,
    isLoading: state.participantsReducer.loading,
    isSuccessfullyRegistered:
      state.participantsReducer.isSuccessfullyRegistered,
    isValidData: state.participantsReducer.isValidData,
    searchedCompanies: state.participantsReducer.searchedCompanies,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      getPackagePricing,
      publicRegisterCompanyAndParticipants,
      publicRegisterParticipants,
      getSearchedCompanies,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
