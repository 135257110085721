import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../../store/Store";
import { loginUser } from "../modules/actions";

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      loginUser,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
