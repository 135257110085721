import { MenuProps } from "antd";
import { DashboardOutlined, UsergroupDeleteOutlined } from "@ant-design/icons";

import { NavigationPath } from "./navigationPath";

export const sidebarMenuItems: MenuProps["items"] = [
  {
    label: <div className="lg:text-dark-blue">Kontrolna ploča</div>,
    icon: (
      <div>
        <DashboardOutlined className="text-dark-blue" />
      </div>
    ),
    key: NavigationPath.DASHBOARD_ROUTE,
  },
  {
    label: <div className="lg:text-dark-blue">Učesnici</div>,
    icon: (
      <div>
        <UsergroupDeleteOutlined className="text-dark-blue" />
      </div>
    ),
    key: NavigationPath.PARTICIPANTS_ROUTE,
  },
];
