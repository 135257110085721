import { ConnectedProps } from "react-redux";
import { Layout, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import userLogo from "../../images/Header Leasing Admin.svg";
import cssLogo from "../../images/css-logo.svg";
import { navbarMenuItems as items } from "../../utils";
import connector from "./Container";

const { Header } = Layout;

const Navbar = ({ state, actions }: ConnectedProps<typeof connector>) => {
  const { loggedUser } = state;

  const onClick = ({ key }: { key: string }) => {
    if (key === "logout") {
      actions.logoutUser();
    }
  };

  return (
    <div>
      <Layout>
        <Header className="bg-white flex items-center justify-between h-20 border-b-2 border-custom-pink-900">
          <div className="flex justify-center h-[80px] 2xl:h-auto  -ml-2">
            <img src={cssLogo} alt="cssLogo" className="py-2 " />
          </div>

          <Dropdown
            className="h-8"
            trigger={["click"]}
            menu={{
              items,
              onClick,
            }}
          >
            <a
              className="flex items-center"
              onClick={(e) => e.preventDefault()}
            >
              <img src={userLogo} />
              <div className="text-dark-blue ml-2 mr-3">{loggedUser?.role}</div>
              <DownOutlined className="text-dark-blue" />
            </a>
          </Dropdown>
        </Header>
      </Layout>
    </div>
  );
};

export default Navbar;
