const StepTwo = ({
  setWayOfParticipation,
  setCurrent,
}: {
  setWayOfParticipation: React.Dispatch<React.SetStateAction<string | null>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <div className="text-center mt-4 sm:mt-6 sm:mb-20">
      <div className="text-custom-gray-700  text-[14px] sm:text-[19px] 2xl:text-[24px] mb-4 sm:mb-6">
        Odaberite način na koji želite učestvovati!
      </div>
      <div className="w-[90%] xl:w-4/5 m-auto sm:grid grid-cols-2 gap-8 ">
        <div className="h-[230px] lg:h-[260px] xl:h-[280px] 2xl:h-[310px] flex justify-center items-center  bg-custom-pink-100 border-2 border-custom-pink-900 rounded-[5px] mb-5 sm:mb-0">
          <div>
            <div className="text-custom-pink-900 text-[24px] lg:text-[28px] 2xl:text-[32px] font-bold">
              <div>PRAVNO LICE</div>
            </div>
            <button
              onClick={() => {
                setWayOfParticipation("PRAVNO LICE");
                setCurrent(2);
              }}
              className="font-bold text-white text-[14px] xl:text-[16px] py-[6px] px-4 font-saira  bg-custom-pink-900 mt-4 rounded-[2px] shadow"
            >
              ODABERI
            </button>
          </div>
        </div>

        <div className="h-[230px] lg:h-[260px] xl:h-[280px] 2xl:h-[310px] flex justify-center items-center  bg-custom-gray-200 border-2 border-custom-gray-800 rounded-[5px]">
          <div>
            <div className="text-custom-gray-800 text-[24px] lg:text-[28px] 2xl:text-[32px] font-bold ">
              <div>FIZIČKO LICE</div>
            </div>
            <button
              onClick={() => {
                setWayOfParticipation("FIZIČKO LICE");
                setCurrent(2);
              }}
              className="font-bold text-white text-[14px] xl:text-[16px] py-[6px] px-4 font-saira  bg-custom-gray-800 mt-4 rounded-[2px] shadow"
            >
              ODABERI
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
