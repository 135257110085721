import React, { useEffect } from "react";
import { ConnectedProps } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Space, Button, Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { type LoginFieldType as FieldType } from "../../../models";
import connector from "./Container";
import { NavigationPath } from "../../../utils";
import cssLogo from "../../../images/css-logo.svg";
import styles from "../Authentication.module.scss";

const Login = ({ actions }: ConnectedProps<typeof connector>) => {
  useEffect(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
  }, []);

  const onFinish = (values: FieldType) => {
    actions.loginUser(values);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className=" w-[360px] m-auto shrink-0">
        <img src={cssLogo} alt="cssLogo" className="mb-6" />

        <Form
          name="basic"
          initialValues={{
            rememberMe: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item<FieldType>
            name="email"
            className={styles.loginInput}
            rules={[
              {
                required: true,
                message: "Please input email!",
              },
              { type: "email" },
            ]}
          >
            <Input
              type="email"
              prefix={<UserOutlined />}
              className="h-10 text-[16px]"
              placeholder="email"
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="password"
            className={styles.loginInput}
            rules={[
              {
                required: true,
                message: "Please input password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              className="h-10 text-[16px]"
              placeholder="Lozinka"
            />
          </Form.Item>

          <Space className="flex justify-between">
            <Form.Item<FieldType>
              name="rememberMe"
              className={styles.loginCheckbox}
              valuePropName="checked"
            >
              <Checkbox>Zapamti me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Link
                className="text-[16px] text-dark-blue"
                to={NavigationPath.RESET_PASSWORD_ROUTE}
              >
                Zaboravljena lozinka?
              </Link>
            </Form.Item>
          </Space>

          <Form.Item>
            <Button
              className="bg-custom-pink-900 border-custom-pink-900 text-white w-[115px] h-10 text-[16px] rounded-sm"
              htmlType="submit"
            >
              PRIJAVA
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="text-sm text-black text-opacity-45 text-center mb-3">
        Copyright ©2024 Produced by TechForgeLabs d.o.o.
      </div>
    </div>
  );
};

export default Login;
