import { Action } from "redux";

import {
  GET_PACKAGE_PRICING,
  GET_MESSAGES,
  GET_EVENT_IMAGE,
  IS_LOADING,
} from "./types";
import { MessagesType } from "../../../models";

type AppAction = {
  type:
    | "GET_MESSAGES"
    | "GET_PACKAGE_PRICING"
    | "IS_LOADING"
    | "GET_EVENT_IMAGE";
  payload: any;
};

const initialState = {
  messages: null,
  packagePricing: null,
  eventImage: null,
  loading: false,
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case GET_PACKAGE_PRICING:
      return {
        ...state,
        packagePricing: action.payload,
      };
    case GET_EVENT_IMAGE:
      return {
        ...state,
        eventImage: action.payload,
      };
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
