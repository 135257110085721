import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../../store/Store";
import {
  getSearchedCompanies,
  registerCompanyAndParticipant,
  registerParticipant,
} from "../modules/actions";
import { getPackagePricing } from "../../Dashboard/modules/actions";

const mapStateToProps = (state: AppState) => ({
  state: {
    loggedUser: state.user.loggedUser,
    packagePricing: state.dashboardReducer.packagePricing,
    searchedCompanies: state.participantsReducer.searchedCompanies,
    loading: state.participantsReducer.loading,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      getPackagePricing,
      registerCompanyAndParticipant,
      registerParticipant,
      getSearchedCompanies,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
