import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import cssLogo from "../../images/css-logo.svg";
import Footer from "../Footer";
import { NavigationPath } from "../../utils";

const Impressum = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-center border-b-2 border-custom-pink-900 h-[80px] 2xl:h-auto">
        <img src={cssLogo} alt="cssLogo" className="py-2" />
      </div>

      <div className="py-10 text-center flex-grow">
        <div className="flex flex-col gap-5  font-semibold md:text-[20px] lg:text-[24px] xl:text-[16px] 2xl:text-[22px]">
          <h1 className="text-[36px] 2xl:text-[50px] font-extrabold text-custom-gray-800 mb-2">
            Impressum
          </h1>
          <p>INFORMACIJE O PRODAJNOM MJESTU</p>
          <p>Udruženje e-Transformacija</p>
          <div>
            <p>Džemala Bijedića 162 / 2</p>
            <p>71000 Sarajevo</p>
            <p>Bosna i Hercegovina</p>
          </div>
          <p>+387 60 322 4596</p>

          <div>
            <p>hello@e-transformacija.ba</p>
            <p>https://e-transformacija.ba/</p>
          </div>
          <p>ID BROJ: 4203246940005</p>
          <Button
            onClick={() => navigate(NavigationPath.REGISTER_ROUTE)}
            htmlType="button"
            className="w-fit m-auto text-white bg-custom-pink-900 border border-custom-pink-900   h-10 sm:text-[16px] font-bold rounded-[2px] shadow mt-6"
          >
            NAZAD NA REGISTRACIJU
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Impressum;
