import React, { useEffect, useState } from "react";

import { PackagePricingType } from "../../../models";

const StepOne = ({
  packagePricing,
  setBundle,
  setCurrent,
}: {
  packagePricing: PackagePricingType[];
  setBundle: React.Dispatch<React.SetStateAction<PackagePricingType | null>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [packageA, setPackageA] = useState<PackagePricingType | null>(null);
  const [packageB, setPackageB] = useState<PackagePricingType | null>(null);

  useEffect(() => {
    packagePricing?.map((item: PackagePricingType) => {
      if (item.package === "A") {
        setPackageA(item);
      } else if (item.package === "B") {
        setPackageB(item);
      }
    });
  }, [packagePricing]);

  return (
    <div className=" text-center my-4 sm:my-6">
      <div className="text-custom-gray-700  text-[14px] sm:text-[19px] 2xl:text-[24px] mb-4 sm:mb-6">
        Odaberite željeni paket!
      </div>
      <div className="w-[90%] xl:w-4/5 m-auto sm:grid grid-cols-2 gap-8">
        <div className="h-[420px] lg:h-[450px] xl:h-[500px] 2xl:h-[560px]  bg-custom-pink-100 border-2 border-custom-pink-900 rounded-[5px] p-6 pb-10 mb-5 sm:mb-0">
          <div>
            <div className="text-custom-pink-900 text-[28px] xl:text-[34px] 2xl:text-[40px] font-black font-saira">
              PAKET 1
            </div>
            <div className="text-custom-pink-900 text-[16px] leading-[20px] xl:text-[20px] xl:leading-[24px] 2xl:text-[24px]  2xl:leading-[28px]  font-bold font-lato  mt-1">
              ULAZNICA +<div>NETWORKING PARTY</div>
            </div>
          </div>
          <div className="text-start text-custom-gray-800 text-[15px] lg:text-[18px] xl:text-[20px] 2xl:text-[24px] my-5 lg:pl-12">
            <div>o  Ulaznica za konferenciju za oba dana</div>
            <div>o  Ručak za oba dana</div>
            <div>o  Sve caffe pauze</div>
            <div>
              o  Pristup online platformi za učenje –
              <div className="ml-3 2xl:ml-5">
                osnovni trening za cyber sigurnost
              </div>
            </div>
            <div className="flex">
              o  
              <div className="text-custom-pink-900">
                Ulaznica za networking party
              </div>
            </div>
          </div>
          <div>
            <div className="text-custom-pink-900 text-[28px] xl:text-[34px] 2xl:text-[40px] font-extrabold font-saira flex justify-center">
              <div>{packageA && packageA?.price} KM</div>
              <div className="text-[16px] xl:text-[20px] 2xl:text-[24px]">
                *
              </div>
            </div>
            <button
              disabled={!packageA}
              onClick={() => {
                setBundle(packageA);
                setCurrent(1);
              }}
              className=" font-bold text-white text-[14px] xl:text-[16px] py-[6px] px-4 font-saira  bg-custom-pink-900 mt-4 rounded-[2px] shadow"
            >
              ODABERI
            </button>
          </div>
        </div>

        <div className="h-[420px] lg:h-[450px] xl:h-[500px] 2xl:h-[560px]  bg-custom-gray-200  border-2 border-custom-gray-800 rounded-[5px] p-6 pb-10">
          <div>
            <div className="text-custom-gray-800 text-[28px] xl:text-[34px] 2xl:text-[40px] font-black font-saira">
              PAKET 2
            </div>
            <div className="text-custom-gray-800 text-[16px] leading-[20px] xl:text-[20px] xl:leading-[24px] 2xl:text-[24px]  2xl:leading-[28px]  font-bold font-lato  mt-1">
              ULAZNICA
            </div>
          </div>
          <div className="text-start text-custom-gray-800 text-[15px] lg:text-[18px] xl:text-[20px] 2xl:text-[24px] mt-10 xl:mt-11 2xl:mt-12   mb-[43px] lg:mb-[47px] xl:mb-[50px] 2xl:mb-14 lg:pl-12">
            <div>o  Ulaznica za konferenciju za oba dana</div>
            <div>o  Ručak za oba dana</div>
            <div>o  Sve caffe pauze</div>
            <div>
              o  Pristup online platformi za učenje –
              <div className="ml-3 2xl:ml-5">
                osnovni trening za cyber sigurnost
              </div>
            </div>
          </div>
          <div>
            <div className="text-custom-gray-800 text-[28px] xl:text-[34px] 2xl:text-[40px] font-extrabold font-saira flex justify-center">
              <div>{packageB && packageB?.price} KM</div>
              <div className="text-custom-pink-900 text-[16px] xl:text-[20px] 2xl:text-[24px]">
                *
              </div>
            </div>
            <button
              disabled={!packageB}
              onClick={() => {
                setBundle(packageB);
                setCurrent(1);
              }}
              className="font-bold text-white text-[14px] xl:text-[16px] py-[6px] px-4 font-saira  bg-custom-gray-800 mt-4 rounded-[2px] shadow"
            >
              ODABERI
            </button>
          </div>{" "}
        </div>
      </div>
      <div className="text-custom-gray-800 flex justify-center font-saira text-[12px] 2xl:text-[14px] mt-9">
        <div className="text-custom-pink-900">*</div> Navedene cijene iskazane
        bez PDV-a
      </div>
    </div>
  );
};

export default StepOne;
