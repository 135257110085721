import axios from "axios";

import { BASE_URL } from "../utils";

const defaultOptions = (url: string | undefined, isFormData = false) => ({
  baseURL: `${url}`,
  headers: {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  },
});

const blobOptions = (url: string | undefined): any => ({
  baseURL: `${url}`,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/pdf",
  },
});

const getToken = () => `Bearer ${localStorage.getItem("token")}`;

export const ApiService = axios.create(defaultOptions(BASE_URL));
export const AuthorizedApiService = axios.create(defaultOptions(BASE_URL));
export const AuthorizedFormApiService = axios.create(
  defaultOptions(BASE_URL, true)
);
export const BlobApiService = axios.create(blobOptions(BASE_URL));

AuthorizedApiService.interceptors.request.use((req) => {
  req.headers.Authorization = getToken();
  return req;
});
AuthorizedApiService.interceptors.response.use(
  (res) => res,
  (err) => err
);

AuthorizedFormApiService.interceptors.request.use((req) => {
  req.headers.Authorization = getToken();
  return req;
});
AuthorizedFormApiService.interceptors.response.use(
  (res) => res,
  (err) => err
);

BlobApiService.interceptors.request.use((req) => {
  req.headers.Authorization = getToken();
  return req;
});
BlobApiService.interceptors.response.use(
  (res) => res,
  (err) => err
);
