import { Key } from "react";
import { Action, Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import { notification } from "antd";

import {
  ApiService,
  AuthorizedApiService,
  BlobApiService,
} from "../../../services/apiServices";
import {
  CompaniesResponse,
  PrivateRegisterFormValues,
  type CompanyAndParticipantsReqType,
  type ParticipantsSignleBasedReqType,
  type RegisterFieldType,
} from "../../../models";
import {
  GET_PARTICIPANTS,
  IS_SUCCESSFULLY_REGISTERED,
  GET_PARTICIPANT_BY_ID,
  IS_APPROVED,
  IS_LOADING,
  GET_COMPANY,
  GET_COMPANY_BY_ID,
  GET_SEARCHED_COMPANIES,
  CHECK_COMPANIES_AND_PARTICIPANTS,
} from "./types";

const setLoading = (loading: boolean) => ({
  type: IS_LOADING,
  payload: loading,
});

export const publicRegisterCompanyAndParticipants =
  (companyAndParticipants: CompanyAndParticipantsReqType) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: CHECK_COMPANIES_AND_PARTICIPANTS,
        payload: false,
      });
      dispatch(setLoading(true));
      ApiService.post("participant/company", companyAndParticipants)
        .then((res: AxiosResponse) => {
          const { status } = res;
          if (status !== 201) {
            notification.error({
              message: "Invalid Register!",
            });
            return;
          }
          companyAndParticipants.sendInvoice
            ? dispatch({
                type: IS_SUCCESSFULLY_REGISTERED,
                payload: true,
              })
            : res.data.message === "validData" &&
              dispatch({
                type: CHECK_COMPANIES_AND_PARTICIPANTS,
                payload: true,
              });
        })
        .catch((err: any) => {
          dispatch({
            type: CHECK_COMPANIES_AND_PARTICIPANTS,
            payload: false,
          });

          if (err.response.data.message) {
            notification.error({
              message: `${err.response.data.message}!`,
            });
            return;
          }
          notification.error({
            message: "Something went wrong",
          });
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
      dispatch(setLoading(false));
      dispatch({
        type: CHECK_COMPANIES_AND_PARTICIPANTS,
        payload: false,
      });
    }
  };

export const publicRegisterParticipants =
  (participants: ParticipantsSignleBasedReqType) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: CHECK_COMPANIES_AND_PARTICIPANTS,
        payload: false,
      });
      dispatch(setLoading(true));
      ApiService.post("participant/single-based", participants)
        .then((res: AxiosResponse) => {
          const { status } = res;
          if (status !== 201) {
            notification.error({
              message: "Invalid Register!",
            });
            return;
          }
          participants.sendInvoice
            ? dispatch({
                type: IS_SUCCESSFULLY_REGISTERED,
                payload: true,
              })
            : res.data.message === "validData" &&
              dispatch({
                type: CHECK_COMPANIES_AND_PARTICIPANTS,
                payload: true,
              });
        })
        .catch((err: any) => {
          dispatch({
            type: CHECK_COMPANIES_AND_PARTICIPANTS,
            payload: false,
          });
          if (err.response.data.message) {
            notification.error({
              message: `${err.response.data.message}!`,
            });
            return;
          }
          notification.error({
            message: "Something went wrong",
          });
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
      dispatch(setLoading(false));
      dispatch({
        type: CHECK_COMPANIES_AND_PARTICIPANTS,
        payload: false,
      });
    }
  };

export const registerParticipant =
  (participant: PrivateRegisterFormValues) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(setLoading(true));
      AuthorizedApiService.post("participant", participant)
        .then((res: any) => {
          const { status } = res;

          if (status !== 201) {
            if (
              res.response.data.message ===
                "Participant with that email already exists" ||
              res.response.data.message === "Verification email not sent"
            ) {
              notification.error({
                message: `${res.response.data.message}!`,
              });
              return;
            }

            notification.error({
              message: "Invalid Register!",
            });
            return;
          }

          notification.success({
            message: "New participant registered!",
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Invalid Register!",
          });
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const getParticipants = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: GET_PARTICIPANT_BY_ID,
      payload: null,
    });

    dispatch({
      type: IS_SUCCESSFULLY_REGISTERED,
      payload: false,
    });
    AuthorizedApiService.get("/participant")
      .then((res: AxiosResponse) => {
        const { status, data } = res;
        if (status !== 200) {
          notification.error({
            message: "Something went wrong",
          });
          return;
        }

        dispatch({
          type: GET_PARTICIPANTS,
          payload: data,
        });
      })
      .catch((err: AxiosError) => {
        notification.error({
          message: "Something went wrong",
        });
      });
  } catch (err) {
    notification.error({
      message: "Something went wrong",
    });
  }
};

export const getParticipantById =
  (id: string | undefined) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: IS_APPROVED,
        payload: false,
      });
      AuthorizedApiService.get(`/participant/${id}`)
        .then((res: AxiosResponse) => {
          const { status, data } = res;
          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          dispatch({
            type: GET_PARTICIPANT_BY_ID,
            payload: data,
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const updateParticipant =
  (participant: PrivateRegisterFormValues, id: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      AuthorizedApiService.put(`/participant/${id}`, participant)
        .then((res: any) => {
          const { status } = res;
          if (status !== 200) {
            if (
              res.response.data.message ===
                "Participant with that email already exists" ||
              res.response.data.message === "Verification email not sent"
            ) {
              notification.error({
                message: `${res.response.data.message}!`,
              });
              return;
            }

            notification.error({
              message: "Invalid Update!",
            });
            return;
          }
          notification.success({
            message: "Participant updated",
          });
          dispatch(getParticipantById(id));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Invalid Update!",
          });
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const updateParticipantStatus =
  (id: string | undefined, status: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.put(`/participant/${id}/status`, { status })
        .then((res: AxiosResponse) => {
          const { status } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          dispatch(getParticipantById(id));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const deleteParticipant =
  (ids: Key[]) => async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.delete(`participant`, {
        data: { ids },
      })
        .then((res) => {
          const { status } = res;
          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }
          notification.success({
            message:
              ids.length === 1 ? "Participant deleted" : "Participants deleted",
          });
          dispatch(getParticipants());
        })
        .catch((err) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const registerCompanyAndParticipant =
  (
    values: PrivateRegisterFormValues,
    newParticipant: PrivateRegisterFormValues
  ) =>
  async (dispatch: any) => {
    const company = {
      name: values.companyName,
      address: values.address,
      postalCodeAndCity: values.postalCodeAndCity,
      country: values.country,
      email: values.companyEmail,
      phoneNumber: values.phoneNumber,
      idNumber: values.ID,
      vatNumber: values.PDV ? values.PDV : null,
      entityId: newParticipant.entityId,
    };

    try {
      AuthorizedApiService.post("/company", company)
        .then((res: any) => {
          const { status, data } = res;
          if (res?.response?.data.message) {
            notification.error({
              message: `${res?.response?.data.message}!`,
            });
            return;
          }
          if (status !== 201) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }
          notification.success({
            message: "New company registered",
          });

          newParticipant.companyId = data.id;
          newParticipant.companyName = data.name;
          dispatch(registerParticipant(newParticipant));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const getCompanies = () => async (dispatch: Dispatch<Action>) => {
  try {
    ApiService.get("company")
      .then((res: AxiosResponse) => {
        const { status, data } = res;

        if (status !== 200) {
          notification.error({
            message: "Something went wrong",
          });
          return;
        }

        dispatch({
          type: GET_COMPANY,
          payload: data,
        });
      })
      .catch((err: AxiosError) => {
        notification.error({
          message: "Something went wrong",
        });
      });
  } catch (err) {
    notification.error({
      message: "Something went wrong",
    });
  }
};

export const getCompanyById =
  (id: string) => async (dispatch: Dispatch<Action>) => {
    try {
      ApiService.get(`/company/${id}`)
        .then((res: AxiosResponse) => {
          const { status, data } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }
          dispatch({
            type: GET_COMPANY_BY_ID,
            payload: data,
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const getSearchedCompanies =
  (search = null) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      ApiService.get(`/company`, { params: { search } })
        .then((res: AxiosResponse) => {
          const { status, data } = res;
          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          dispatch({
            type: GET_SEARCHED_COMPANIES,
            payload: data.length > 0 ? data : [],
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const updateCompany =
  (company: CompaniesResponse, id: string) => async (dispatch: any) => {
    try {
      AuthorizedApiService.put(`/company/${id}`, company)
        .then((res: AxiosResponse) => {
          const { status, data } = res;
          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }
          notification.success({
            message: "Company updated",
          });
          dispatch(getCompanyById(id));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

export const printTicket =
  (id: string) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(setLoading(true));

      BlobApiService.get(`/participant/${id}/accreditation`)
        .then((res: AxiosResponse) => {
          const { status, data } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            dispatch(setLoading(false));
            return;
          }

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ticket_${id}.pdf`);
          document.body.appendChild(link);
          link.click();
          notification.success({
            message: `Ticket downloaded`,
          });

          dispatch(setLoading(false));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
          dispatch(setLoading(false));
        });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
