import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import cssLogo from "../../images/css-logo.svg";
import Footer from "../Footer";
import { NavigationPath } from "../../utils";

const PrivacyStatement = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-center border-b-2 border-custom-pink-900 h-[80px] 2xl:h-auto">
        <img src={cssLogo} alt="cssLogo" className="py-2" />
      </div>

      <div className="py-10  flex-grow">
        <div className="flex flex-col gap-5 md:text-[24px] lg:text-[28px] xl:text-[16px] 2xl:text-[26px]">
          <h1 className="text-center text-[36px] 2xl:text-[50px] font-extrabold text-custom-gray-800 mb-2">
            Izjava o privatnosti
          </h1>
          <div className="w-4/5 m-auto">
            <p className="mb-5">
              Obavezujemo se pružati zaštitu ličnih podataka kupaca, na način da
              prikupljamo samo nužne, osnovne podatke o kupcima / korisnicima
              koji su nužni za ispunjenje naših obaveza; informišemo kupce o
              načinu korištenja prikupljenih podataka, redovno dajemo kupcima
              mogućnost izbora o upotrebi njihovih podataka, uključujući
              mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista
              koje se koriste za marketinške kampanje.
            </p>
            <p>
              Svi se podaci o korisnicima strogo čuvaju i dostupni su samo
              uposlenicima kojima su ti podaci nužni za obavljanje posla. Svi
              naši uposlenici i poslovni partneri odgovorni su za poštovanje
              načela zaštite privatnosti.
            </p>
          </div>

          <Button
            onClick={() => navigate(NavigationPath.REGISTER_ROUTE)}
            htmlType="button"
            className="w-fit m-auto text-white bg-custom-pink-900 border border-custom-pink-900   h-10 sm:text-[16px] font-bold rounded-[2px] shadow mt-10 xl:mt-6"
          >
            NAZAD NA REGISTRACIJU
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyStatement;
