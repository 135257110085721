import React from "react";
import { Button, Flex, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { type ActionButtonsType } from "../../models";

const ActionButtons = ({
  navigateToAdd,
  navigateToEdit,
  handleDelete,
  selected,
}: ActionButtonsType) => {
  return (
    <Flex gap="small">
      <Button
        onClick={navigateToAdd}
        className="text-xs md:text-sm text-white bg-custom-pink-900 font-medium xl:w-[106px] 2xl:w-[132px] rounded-sm border-custom-pink-900 shadow-sm mt-4 md:mt-0 mb-4 xl:mb-0"
        icon={<PlusOutlined className="text-[10px] md:text-sm" />}
      >
        Dodaj
      </Button>
      <Button
        disabled={!selected || selected?.length !== 1}
        onClick={navigateToEdit}
        className="text-xs md:text-sm text-white bg-custom-gray-600 font-medium xl:w-[106px] 2xl:w-[132px] rounded-sm border-custom-gray-700 shadow-sm mt-4 md:mt-0 mb-4 xl:mb-0"
        icon={<EditOutlined className="text-[10px] md:text-sm" />}
      >
        Uredi
      </Button>
      <Popconfirm
        okType="default"
        title={
          selected?.length === 1
            ? `Obriši učesnika ${selected[0]?.fullName}`
            : `Obriši izabrane učesnike`
        }
        onConfirm={handleDelete}
        okText="Da"
        cancelText="Ne"
      >
        <Button
          disabled={!selected}
          className="text-xs md:text-sm text-white bg-custom-red-900 font-medium xl:w-[106px] 2xl:w-[132px] rounded-sm border-custom-red-800 shadow-sm mt-4 md:mt-0 mb-4 xl:mb-0"
          icon={<DeleteOutlined className="text-[10px] md:text-sm" />}
        >
          Obriši
        </Button>
      </Popconfirm>
    </Flex>
  );
};

export default ActionButtons;
