import React from "react";
import { Routes, Route } from "react-router-dom";

import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./privateRoute";
import Login from "./components/Authentication/Login";
import { NavigationPath } from "./utils";
import Dashboard from "./components/Dashboard";
import Participants from "./components/Participants";
import AddParticipant from "./components/Participants/AddParticipant";
import EditParticipant from "./components/Participants/EditParticipant";
import Welcome from "./components/AfterScan";
import ResetPassword from "./components/Authentication/ResetPassword";
import Register from "./components/Register";
import Impressum from "./components/FooterLinks/Impressum";
import PaymentSecurity from "./components/FooterLinks/PaymentSecurity";
import PrivacyStatement from "./components/FooterLinks/PrivacyStatement";
import PurchaseTerms from "./components/FooterLinks/PurchaseTerms";

const AppContainer = () => {
  const loggedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const role = loggedUser?.role;

  return (
    <Routes>
      <Route path={NavigationPath.REGISTER_ROUTE} element={<Register />} />
      <Route path={NavigationPath.LOGIN_ROUTE} element={<Login />} />
      <Route
        path={NavigationPath.RESET_PASSWORD_ROUTE}
        element={<ResetPassword />}
      />
      <Route path={NavigationPath.IMPRESSUM} element={<Impressum />} />
      <Route
        path={NavigationPath.PAYMENT_SECURITY}
        element={<PaymentSecurity />}
      />
      <Route
        path={NavigationPath.PRIVACY_STATEMENT}
        element={<PrivacyStatement />}
      />
      <Route path={NavigationPath.PURCHASE_TERMS} element={<PurchaseTerms />} />

      <Route path={"/"} element={<PrivateRoute />}>
        {role === "Admin" && (
          <Route
            path={NavigationPath.DASHBOARD_ROUTE}
            element={<Dashboard />}
          />
        )}
        <Route
          path={NavigationPath.PARTICIPANTS_ROUTE}
          element={<Participants />}
        />
        <Route
          path={NavigationPath.ADD_PARTICIPANT_ROUTE}
          element={<AddParticipant />}
        />
        <Route
          path={NavigationPath.EDIT_PARTICIPANT_ROUTE}
          element={<EditParticipant />}
        />
        <Route path={NavigationPath.AFTER_SCAN_ROUTE} element={<Welcome />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppContainer;
