import { combineReducers } from "redux";

import UserReducer from "../components/Authentication/modules/reducer";
import ParticipantsReducer from "../components/Participants/modules/reducer";
import DashboardReducer from "../components/Dashboard/modules/reducer";

export default combineReducers({
  user: UserReducer,
  participantsReducer: ParticipantsReducer,
  dashboardReducer: DashboardReducer,
});
