export const GET_PARTICIPANTS = "GET_PARTICIPANTS";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_SEARCHED_COMPANIES = "GET_SEARCHED_COMPANIES";
export const IS_SUCCESSFULLY_REGISTERED = "IS_SUCCESSFULLY_REGISTERED";
export const CHECK_COMPANIES_AND_PARTICIPANTS =
  "CHECK_COMPANIES_AND_PARTICIPANTS";
export const GET_PARTICIPANT_BY_ID = "GET_PARTICIPANT_BY_ID";
export const IS_APPROVED = "IS_APPROVED";
export const IS_LOADING = "IS_LOADING";
