import React, { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { Button, Col, Row, Space, Spin, type UploadProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import UploadImage from "./UploadImage";
import SelectExportFormat from "./SelectExportFormat";
import MessagesAndPackagePricing from "./MessagesAndPackagePricing";
import connector from "./Container";
import {
  MessagesPackagePricingFormValuesType,
  PackagePricingType,
} from "../../models";
import exportIcon from "../../images/export.svg";

const Dashboard = ({ state, actions }: ConnectedProps<typeof connector>) => {
  const { loggedUser, messages, packagePricing, eventImage, isUploading } =
    state;
  const [loaded, setLoaded] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [packageA, setPackageA] = useState<PackagePricingType | null>(null);
  const [packageB, setPackageB] = useState<PackagePricingType | null>(null);

  useEffect(() => {
    actions.getMessages();
    actions.getPackagePricing();
  }, []);

  useEffect(() => {
    packagePricing?.map((item: PackagePricingType) => {
      if (item.package === "A") {
        setPackageA(item);
      } else if (item.package === "B") {
        setPackageB(item);
      }
    });
  }, [packagePricing]);

  useEffect(() => {
    loggedUser?.entityId && actions.getEventImageById(loggedUser?.entityId);
  }, [loggedUser]);

  useEffect(() => {
    if (messages !== null && packagePricing !== null) {
      setLoaded(true);
      return;
    }
    setLoaded(false);
  }, [messages, packagePricing]);

  const onFinish = (values: MessagesPackagePricingFormValuesType) => {
    const msg = {
      registeredUserBos: values.registeredUserBos,
      registeredUserEng: " ",
      confirmedUserBos: values.confirmedUserBos,
      confirmedUserEng: " ",
      entityId: loggedUser?.entityId,
    };

    if (!messages?.id) {
      actions.setMessages(msg);
    } else {
      isMessageChanged(values) && actions.updateMessages(msg, messages.id);
    }

    const packagePricingA: PackagePricingType = {
      package: "A",
      price: values.packageA,
      vat: values.VAT,
      entityId: loggedUser?.entityId,
    };
    const packagePricingB: PackagePricingType = {
      package: "B",
      price: values.packageB,
      vat: values.VAT,
      entityId: loggedUser?.entityId,
    };

    if (!packageA?.id) {
      actions.createPackagePricing(packagePricingA);
    } else {
      isPackageAChanged(values) &&
        actions.updatePackagePricing(packagePricingA, packageA.id);
    }

    if (!packageB?.id) {
      actions.createPackagePricing(packagePricingB);
    } else {
      isPackageBChanged(values) &&
        actions.updatePackagePricing(packagePricingB, packageB.id);
    }

    setIsDisabledButton(true);
  };

  const isMessageChanged = (values: MessagesPackagePricingFormValuesType) => {
    return messages?.registeredUserBos !== values.registeredUserBos ||
      messages?.confirmedUserBos !== values.confirmedUserBos
      ? true
      : false;
  };

  const isPackageAChanged = (values: MessagesPackagePricingFormValuesType) => {
    return packageA?.price != values.packageA || packageA?.vat != values.VAT
      ? true
      : false;
  };

  const isPackageBChanged = (values: MessagesPackagePricingFormValuesType) => {
    return packageB?.price != values.packageB || packageB?.vat != values.VAT
      ? true
      : false;
  };

  const uploadImage = (info: UploadProps) => {
    actions.addEventImage(info, loggedUser?.entityId);
  };

  const selectFormat = ({ format }: { format: string }) => {
    loggedUser?.entityId &&
      actions.exportFormat(loggedUser?.entityId, format.substring(1));
  };

  const generateInvoice = () => {
    actions.exportInvoice();
  };

  return loaded ? (
    <Space direction="vertical" className="px-4 py-6 sm:p-8 w-full">
      <Row>
        <Col span={24} md={12}>
          <MessagesAndPackagePricing
            messages={messages}
            onFinish={onFinish}
            isDisabled={isDisabledButton}
            setIsDisabled={setIsDisabledButton}
            packageA={packageA}
            packageB={packageB}
          />
        </Col>
        <Col span={24} md={12}>
          <UploadImage
            eventImage={eventImage}
            uploadImage={uploadImage}
            isUploading={isUploading}
          />
          <SelectExportFormat selectFormat={selectFormat} />
          <div className="w-[95%] md:w-[90%] lg:w-4/5   md:ml-10">
            <Button
              className="bg-custom-pink-900 text-white flex items-center justify-center font-medium w-full md:w-auto rounded-sm border-custom-pink-900 shadow mt-8 sm:mt-4"
              icon={<img src={exportIcon} />}
              onClick={generateInvoice}
            >
              IZVEZI PREDRAČUNE
            </Button>
          </div>
        </Col>
      </Row>
    </Space>
  ) : (
    <div className="w-full h-full flex justify-center items-center ">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 46 }} spin />} />
    </div>
  );
};

export default Dashboard;
