import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";

const { Title } = Typography;

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex items-center justify-center text-center">
      <div>
        <Title>Page Not Found</Title>
        <div
          onClick={() => navigate(-1)}
          className="cursor-pointer underline text-xl text-light-blue"
        >
          Go back
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
