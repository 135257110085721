import React from "react";
import { ConnectedProps } from "react-redux";

import AddEditParticipant from "../AddEditParticipant";
import connector from "./Container";

const AddParticipant = ({
  state,
  actions,
}: ConnectedProps<typeof connector>) => {
  return (
    <AddEditParticipant
      state={state}
      actions={actions}
      isAddParticipant={true}
    />
  );
};

export default AddParticipant;
