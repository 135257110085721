import React, { useState } from "react";
import { Button, Form, Radio, Space, Typography } from "antd";

import exportIcon from "../../../images/export.svg";
import { selectFormatProps } from "../../../models";

const SelectExportFormat = ({ selectFormat }: selectFormatProps) => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <div className="w-[95%] md:w-[90%] lg:w-4/5 md:ml-10 bg-upload-bg border border-dashed border-upload-border rounded-lg mt-12 md:mt-6 p-5 pb-4">
      <Typography className="font-semibold mb-1">IZVEZI PRISUTNE</Typography>
      <Typography className="mb-2">Odaberi željeni format:</Typography>

      <Form form={form} name="basic" onFinish={selectFormat}>
        <Form.Item
          name="format"
          rules={[
            {
              required: true,
              message: "Izaberite format!",
            },
          ]}
        >
          <Radio.Group onChange={() => setIsDisabled(false)}>
            <Space direction="vertical">
              <Radio value={".xlsx"}>.xlsx</Radio>
              <Radio value={".csv"}>.csv</Radio>
              <Radio value={".pdf"}>.pdf</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            className="bg-custom-pink-900 text-white flex items-center justify-center font-medium w-full md:w-[132px] rounded-sm border-custom-pink-900 shadow"
            icon={<img src={exportIcon} />}
          >
            IZVEZI
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectExportFormat;
