export const roleOptions = [
  {
    value: "Organizator",
    label: "Organizator",
  },
  {
    value: "Sponzor",
    label: "Sponzor",
  },
  {
    value: "Pozivnica",
    label: "Pozivnica",
  },
  {
    value: "Učesnik",
    label: "Učesnik",
  },
];
