import React, { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Flex, Select, Input, Table } from "antd";

import "../../App.css";
import styles from "./Participant.module.scss";

import ActionButtons from "../ActionButtons";
import {
  type CompaniesResponse,
  type ParticipantResponse,
  type ParticipantsDataTable,
  type ParticipantsDataTable as DataType,
  type PackagePricingType,
} from "../../models";
import {
  NavigationPath,
  participantsColumns as columns,
  showNotification,
  roleOptions,
  participantStatus,
} from "../../utils";
import connector from "./Container";

const { Search } = Input;

const Participants = ({ state, actions }: ConnectedProps<typeof connector>) => {
  const { participants, companies, packagePricing, loggedUser } = state;

  useEffect(() => {
    actions.getParticipants();
    actions.getCompanies();
    actions.getPackagePricing();
  }, []);

  const [selectedParticipant, setSelectedParticipant] = useState<
    null | DataType[]
  >(null);
  const [searchParticipant, setSearchParticipant] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);

  const [filterByPackage, setFilterByPackage] = useState<string | number>("");
  const [filterByRole, setFilterByRole] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [participantTableData, setParticipantTableData] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setParticipantTableData(filterTable(participants));
  }, [participants]);

  useEffect(() => {
    const filtered = filterByStatus
      ? participants.filter(
          (participant: ParticipantResponse) =>
            participant.status === filterByStatus
        )
      : participants;

    setParticipantTableData(filterTable(filtered));
  }, [filterByStatus]);

  useEffect(() => {
    const filtered = filterByPackage
      ? participants.filter(
          (participant: ParticipantResponse) =>
            participant.package.price == filterByPackage
        )
      : participants;

    setParticipantTableData(filterTable(filtered));
  }, [filterByPackage]);

  const getCountryOfParticipant = (id: string | undefined) => {
    const result = companies?.find(
      (company: CompaniesResponse) => company.id === id
    );
    return result.country;
  };

  const filterTable = (data: ParticipantResponse[]) => {
    return data.map((participant: ParticipantResponse) => ({
      key: participant.id,
      id: participant.id,
      fullName: participant.firstName + " " + participant.lastName,
      companyName: participant.companyName
        ? participant.companyName
        : participant.Company?.name
        ? participant.Company?.name
        : "",
      email: participant.email,
      country: participant.country
        ? participant.country
        : getCountryOfParticipant(participant.Company?.id),
      role: participant.role,
    }));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedParticipant(selectedRows);
    },
  };

  const navigateToAddParticipant = () => {
    navigate(NavigationPath.ADD_PARTICIPANT_ROUTE);
  };

  const navigateToEdit = () => {
    if (selectedParticipant?.length === 1) {
      navigate(`/participant/${selectedParticipant[0].id}`);
    } else {
      showNotification("Select one participant to edit");
    }
  };

  const handleDeleteParticipant = () => {
    if (selectedParticipant) {
      const ids = selectedParticipant.map(
        (participant: ParticipantsDataTable) => participant.id
      );

      actions.deleteParticipant(ids);
      setSelectedParticipant(null);
    }
  };

  const onSearch = (value: string) => {
    const MIN_VALUE_LENGTH = 3;
    if (value.length < MIN_VALUE_LENGTH) {
      setCompanyOptions([]);
      return;
    }
    const companyNames = participants.reduce(
      (array: ParticipantsDataTable[], participant: any) => {
        if (!participant?.companyName && !participant?.Company?.name) {
          return array;
        }
        return array.includes(
          participant?.companyName
            ? participant?.companyName
            : participant?.Company?.name
        )
          ? array
          : [
              ...array,
              participant?.companyName
                ? participant?.companyName
                : participant?.Company?.name,
            ];
      },
      []
    );

    setCompanyOptions(
      companyNames.filter((companyName: string) =>
        companyName?.toLowerCase().includes(value?.toLowerCase())
      )
    );
  };

  const onSelectCompany = (value: string) => {
    if (!value) {
      return;
    }
    setSearchCompany(value);
  };

  return (
    <div className="px-4 sm:px-4 2xl:px-8 py-4">
      <div className="xl:flex justify-between mb-4">
        {loggedUser?.role === "Admin" ? (
          <ActionButtons
            navigateToAdd={navigateToAddParticipant}
            navigateToEdit={navigateToEdit}
            handleDelete={handleDeleteParticipant}
            selected={selectedParticipant}
          />
        ) : (
          <div></div>
        )}

        <div className="sm:flex">
          <Select
            value={!filterByPackage ? "Paket" : filterByPackage}
            variant="borderless"
            className={`max-w-24 sm:max-w-[120px] ${styles.grayArrow} ${
              filterByPackage && styles.selectPadding
            }`}
            options={packagePricing?.map(
              (packagePrice: PackagePricingType) => ({
                value: `${packagePrice?.price}`,
                label: `${packagePrice?.package} - ${packagePrice?.price} KM`,
              })
            )}
            onChange={(e) => setFilterByPackage(e)}
            allowClear={filterByPackage ? true : false}
            onClear={() => setFilterByPackage("")}
          />
          <Select
            value={!filterByStatus ? "Status" : filterByStatus}
            variant="borderless"
            className={`max-w-24 sm:max-w-[100px]  ${styles.grayArrow} ${
              filterByStatus && styles.selectPadding
            }`}
            options={participantStatus}
            onChange={(e) => setFilterByStatus(e)}
            allowClear={filterByStatus ? true : false}
            onClear={() => setFilterByStatus("")}
          />
          <Select
            value={!filterByRole ? "Uloga" : filterByRole}
            variant="borderless"
            className={`max-w-20 sm:max-w-[110px] ${styles.grayArrow} ${
              filterByRole && styles.selectPadding
            }`}
            options={roleOptions}
            onChange={(e) => (!e ? setFilterByRole("") : setFilterByRole(e))}
            allowClear={filterByRole ? true : false}
          />

          <Select
            value={!searchCompany ? "Kompanija" : searchCompany}
            variant="borderless"
            className={`max-w-20 sm:max-w-[120px] ${styles.grayArrow} ${styles.selectPadding}`}
            showSearch
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onSearch}
            notFoundContent={null}
            options={companyOptions?.map((company) => ({
              value: company,
              label: company,
            }))}
            onSelect={onSelectCompany}
            allowClear={searchCompany ? true : false}
            onClear={() => {
              setSearchCompany("");
              setCompanyOptions([]);
            }}
          />

          <Search
            className={`w-full lg:w-72 2xl:w-80 mt-4 sm:mt-0 ${styles.searchInput}`}
            placeholder="Traži po imenu / prezimenu korisnika"
            onSearch={(e) => setSearchParticipant(e)}
            enterButton
          />
        </div>
      </div>
      <Table
        columns={columns(searchParticipant, searchCompany, filterByRole)}
        dataSource={participantTableData}
        rowSelection={
          loggedUser?.role === "Admin"
            ? {
                ...rowSelection,
              }
            : undefined
        }
      />
    </div>
  );
};

export default Participants;
