export const participantStatus = [
  {
    value: "Kreiran",
    label: "Kreiran",
  },
  {
    value: "Odobren",
    label: "Odobren",
  },
  {
    value: "Prisutan",
    label: "Prisutan",
  },
];
