import React from "react";
import { Link } from "react-router-dom";

import mastercard from "../../images/mastercard.svg";
import maestro from "../../images/maestro.svg";
import visa from "../../images/visa.svg";
import idCheck from "../../images/id-check.svg";
import visaSecure from "../../images/visa-secure.svg";
import uniCreditBank from "../../images/UniCreditBank.png";
import { NavigationPath } from "../../utils";

const Footer = () => {
  return (
    <div>
      <div className="border-t-2 border-custom-pink-900 mt-4">
        <div className="flex my-10 justify-center text-[12px] sm:text-[14px]">
          <div className="flex gap-10 sm:gap10">
            <div className="flex flex-col items-center sm:flex-row gap-4 lg:gap-10">
              {" "}
              <Link to={NavigationPath.IMPRESSUM}>IMPRESSUM</Link>
              <Link to={NavigationPath.PAYMENT_SECURITY}>
                SIGURNOST PLAĆANJA
              </Link>
            </div>
            <div className="flex flex-col items-center sm:flex-row gap-4 lg:gap-10">
              <Link to={NavigationPath.PRIVACY_STATEMENT}>
                IZJAVA O PRIVATNOSTI
              </Link>
              <Link to={NavigationPath.PURCHASE_TERMS}>USLOVI KUPOVINE</Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div className="flex flex-col items-center  gap-4">
            <Link to={"https://monri.com/"} target="_blank">
              <img
                className="md:hidden h-8  sm:h-10"
                src={uniCreditBank}
                alt="uniCredit"
              />
            </Link>

            <div className="flex justify-center sm:gap-4">
              <Link to={"https://www.mastercard.ba/bs-ba.html"} target="_blank">
                <img src={mastercard} alt="mastercard" className="mt-1" />
              </Link>
              <Link
                to={"https://www.mastercard.com/brandcenter/en/home"}
                target="_blank"
              >
                <img src={maestro} alt="maestro" className="mt-1" />
              </Link>
              <Link
                to={"https://www.visa.co.uk/about-visa/visa-in-europe.html"}
                target="_blank"
              >
                <img src={visa} alt="visa" className="mt-1" />
              </Link>
              <img src={idCheck} alt="idCheck" />
              <img src={visaSecure} alt="visaSecure" />
              <Link to={"https://monri.com/"} target="_blank">
                <img
                  className="hidden md:block h-10"
                  src={uniCreditBank}
                  alt="uniCredit"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
