import { Key } from "react";
import { TableColumnsType } from "antd";

import { type ParticipantsDataTable as DataType } from "../models";

export const participantsColumns = (
  searchParticipant: string,
  searchCompany: string,
  filterByRole: string
) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: "Ime i prezime",
      dataIndex: "fullName",
      filteredValue: [searchParticipant],
      onFilter: (value: boolean | Key, record: DataType) => {
        return record?.fullName
          .toLowerCase()
          .includes(searchParticipant?.toLowerCase());
      },
    },
    {
      title: "Naziv kompanije",
      dataIndex: "companyName",
      filteredValue: [searchCompany],
      onFilter: (value: boolean | Key, record: DataType) => {
        return record?.companyName
          .toLowerCase()
          .includes(searchCompany?.toLowerCase());
      },
    },
    {
      title: "email",
      dataIndex: "email",
      responsive: ["md"],
    },
    {
      title: "Država",
      dataIndex: "country",
      responsive: ["xl"],
    },
    {
      title: "Uloga",
      dataIndex: "role",
      responsive: ["sm"],
      sorter: (a, b) => a.role.localeCompare(b.role),
      filteredValue: [filterByRole],
      onFilter: (value: boolean | Key, record: DataType) => {
        return record?.role.toLowerCase().includes(filterByRole?.toLowerCase());
      },
    },
  ];

  return columns;
};
