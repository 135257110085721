import { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { Button, Form, Input, Spin } from "antd";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";

import { type LoginFieldType as FieldType } from "../../../models";
import connector from "./Container";
import cssLogo from "../../../images/css-logo.svg";
import styles from "../Authentication.module.scss";

const ResetPassword = ({
  state,
  actions,
}: ConnectedProps<typeof connector>) => {
  const { loading } = state;
  const [token, setToken] = useState("");
  const [email, setEmail] = useState<string | undefined>("");

  useEffect(() => {
    const TOKEN_SUBSTRING = 7;
    const userToken = window.location.search.substring(TOKEN_SUBSTRING);
    if (userToken) {
      setToken(userToken);
      setEmail(jwtDecode(userToken).sub);
    }
  }, []);

  const onFinish = (values: FieldType) => {
    !token
      ? actions.sendResetPasswordEmail(values.email)
      : actions.resetPassword(email, values.password, token);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className=" w-[360px] m-auto ">
        <img src={cssLogo} alt="cssLogo" className="mb-6" />

        <Form name="basic" onFinish={onFinish}>
          {!token ? (
            <Form.Item<FieldType>
              name="email"
              className={styles.loginInput}
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                },
                { type: "email" },
              ]}
            >
              <Input
                type="email"
                prefix={<UserOutlined />}
                className="h-10 text-[16px]"
                placeholder="email"
              />
            </Form.Item>
          ) : (
            <Form.Item<FieldType>
              name="password"
              className={styles.loginInput}
              rules={[
                {
                  required: true,
                  message: "Please input new password!",
                },
                { min: 6 },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                className="h-10 text-[16px]"
                placeholder="new password"
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              className="bg-custom-pink-900 border-custom-pink-900 text-white w-full h-10 text-[16px] rounded-sm"
              htmlType="submit"
            >
              POŠALJI
            </Button>
          </Form.Item>
        </Form>
        {loading && (
          <div className="text-center -mb-10">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 40, color: "black" }}
                  spin
                />
              }
            />
          </div>
        )}
      </div>
      <div className="text-sm text-black text-opacity-45 text-center mb-3">
        Copyright ©2024 Produced by TechForgeLabs d.o.o.
      </div>
    </div>
  );
};

export default ResetPassword;
