import React from "react";
import { Input, Button, Form, Radio } from "antd";

import "../../../App.css";
import {
  MessagesPackagePricingFormValuesType,
  MessagesType,
  PackagePricingType,
} from "../../../models";

const { TextArea } = Input;

const MessagesAndPackagePricing = ({
  messages,
  onFinish,
  isDisabled,
  setIsDisabled,
  packageA,
  packageB,
}: {
  messages: MessagesType | null;
  onFinish: (values: MessagesPackagePricingFormValuesType) => void;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  packageA: PackagePricingType | null;
  packageB: PackagePricingType | null;
}) => {
  const [form] = Form.useForm();

  const onValuesChange = (changedValues: object, values: any) => {
    if (
      messages?.registeredUserBos === values.registeredUserBos &&
      messages?.confirmedUserBos === values.confirmedUserBos &&
      packageA?.price == values.packageA &&
      packageB?.price == values.packageB &&
      packageA?.vat === values.VAT
    ) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  };

  return (
    <div className="w-[95%] md:w-[90%] lg:w-4/5">
      <Form
        onValuesChange={onValuesChange}
        form={form}
        name="messagesAndPackagePricing"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          initialValue={messages?.registeredUserBos}
          label="Unesi tekst poruke za registrovanog korisnika [predračun]"
          name="registeredUserBos"
          rules={[
            {
              required: true,
              message: "Obavezno polje!",
            },
          ]}
        >
          <TextArea
            rows={3}
            placeholder="Upiši tekst na bosanskom jeziku"
            className="messagesBorder"
          />
        </Form.Item>

        <Form.Item
          initialValue={messages?.confirmedUserBos}
          label="Unesi tekst poruke za odobrenog korisnika"
          name="confirmedUserBos"
          rules={[
            {
              required: true,
              message: "Obavezno polje!",
            },
          ]}
          className="mt-6"
        >
          <TextArea
            rows={3}
            placeholder="Upiši tekst na bosanskom jeziku"
            className="messagesBorder"
          />
        </Form.Item>

        <Form.Item
          initialValue={packageA && packageA.price}
          label="Cijena Paketa A [KM]"
          name="packageA"
          rules={[
            {
              required: true,
              message: "Obavezno polje!",
            },
          ]}
          className="mt-6"
        >
          <Input placeholder="320" type="number" />
        </Form.Item>

        <Form.Item
          initialValue={packageB && packageB.price}
          label="Cijena Paketa B [KM]"
          name="packageB"
          rules={[
            {
              required: true,
              message: "Obavezno polje!",
            },
          ]}
          className="mt-6"
        >
          <Input placeholder="250" type="number" />
        </Form.Item>

        <Form.Item
          initialValue={
            packageA ? packageA.vat : packageB ? packageB.vat : null
          }
          name="VAT"
          label="PDV"
          rules={[
            {
              required: true,
              message: "Odaberite postotak PDV-a!",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={false}>0%</Radio>
            <Radio value={true}>17%</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isDisabled}
            htmlType="submit"
            className="bg-custom-pink-900 text-white flex items-center justify-center font-medium w-full md:w-[132px] rounded-sm border-custom-pink-900 shadow mb-12 md:mb-0"
          >
            SNIMI
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MessagesAndPackagePricing;
