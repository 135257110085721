import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../store/Store";
import {
  getParticipants,
  deleteParticipant,
  getCompanies,
} from "./modules/actions";
import { getPackagePricing } from "../Dashboard/modules/actions";

const mapStateToProps = (state: AppState) => ({
  state: {
    companies: state.participantsReducer.companies,
    packagePricing: state.dashboardReducer.packagePricing,
    participants: state.participantsReducer.participants,
    loggedUser: state.user.loggedUser,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      getCompanies,
      getPackagePricing,
      getParticipants,
      deleteParticipant,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
