import { Action } from "redux";
import {
  GET_PARTICIPANTS,
  GET_COMPANY,
  GET_COMPANY_BY_ID,
  GET_SEARCHED_COMPANIES,
  IS_SUCCESSFULLY_REGISTERED,
  CHECK_COMPANIES_AND_PARTICIPANTS,
  GET_PARTICIPANT_BY_ID,
  IS_APPROVED,
  IS_LOADING,
} from "./types";

type AppAction = {
  type:
    | "GET_PARTICIPANTS"
    | "GET_COMPANY"
    | "GET_COMPANY_BY_ID"
    | "GET_SEARCHED_COMPANIES"
    | "IS_SUCCESSFULLY_REGISTERED"
    | "CHECK_COMPANIES_AND_PARTICIPANTS"
    | "GET_PARTICIPANT_BY_ID"
    | "IS_APPROVED"
    | "IS_LOADING";
  payload: any;
};

const initialState = {
  participants: [],
  companies: null,
  currentCompany: null,
  searchedCompanies: null,
  isSuccessfullyRegistered: false,
  isValidData: false,
  currentParticipant: null,
  isApproved: false,
  loading: false,
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case GET_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case GET_COMPANY:
      return {
        ...state,
        companies: action.payload,
      };
    case GET_COMPANY_BY_ID:
      return {
        ...state,
        currentCompany: action.payload,
      };
    case GET_SEARCHED_COMPANIES:
      return {
        ...state,
        searchedCompanies: action.payload,
      };
    case IS_SUCCESSFULLY_REGISTERED:
      return {
        ...state,
        isSuccessfullyRegistered: action.payload,
      };

    case CHECK_COMPANIES_AND_PARTICIPANTS:
      return {
        ...state,
        isValidData: action.payload,
      };

    case GET_PARTICIPANT_BY_ID:
      return {
        ...state,
        currentParticipant: action.payload,
      };
    case IS_APPROVED:
      return {
        ...state,
        isApproved: action.payload,
      };
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
