import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { type AppDispatch, type AppState } from "../../store/Store";
import {
  setMessages,
  getMessages,
  updateMessages,
  addEventImage,
  getEventImageById,
  exportFormat,
  getPackagePricing,
  createPackagePricing,
  updatePackagePricing,
  exportInvoice,
} from "./modules/actions";

const mapStateToProps = (state: AppState) => ({
  state: {
    loggedUser: state.user.loggedUser,
    messages: state.dashboardReducer.messages,
    packagePricing: state.dashboardReducer.packagePricing,
    eventImage: state.dashboardReducer.eventImage,
    isUploading: state.dashboardReducer.loading,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      getPackagePricing,
      createPackagePricing,
      updatePackagePricing,
      setMessages,
      getMessages,
      updateMessages,
      addEventImage,
      getEventImageById,
      exportFormat,
      exportInvoice,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
