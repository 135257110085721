import React from "react";
import { Spin, Typography, Upload } from "antd";
import {
  InboxOutlined,
  LoadingOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { uploadImageProps } from "../../../models";
import { imgNameFormatter as imgName } from "../../../utils";

const { Dragger } = Upload;

const UploadImage = ({
  eventImage,
  uploadImage,
  isUploading,
}: uploadImageProps) => {
  return (
    <div className="w-[95%] md:w-[90%] lg:w-4/5  md:ml-10">
      <Typography className="mb-3">
        Dodaj slika događaja dimenzija 369 x 260 px
      </Typography>
      {!isUploading ? (
        <Dragger
          multiple={false}
          maxCount={1}
          accept=".jpg, .png"
          customRequest={uploadImage}
          showUploadList={false}
          name="event"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Klikni ili prevuci sliku za upload</p>
          <p className="ant-upload-hint">
            Ova slika će se prikazati u gornjem dijelu akreditacije koja se
            emailom šalje prisutnim koji su potvrđeni.
          </p>
        </Dragger>
      ) : (
        <div className="h-[171px] text-center  pt-10">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 32, color: "black" }} spin />
            }
          />
          <Typography className="font-semibold mt-6">
            <>Dodaje se slika događaja..</>
          </Typography>
        </div>
      )}
      {eventImage && (
        <div className="flex w-full mt-3">
          <PaperClipOutlined />
          <div className="flex justify-between items-center w-full">
            <a
              className="w-3/5 ml-2 truncate"
              href={eventImage?.imgUrl}
              target="_blank"
            >
              {imgName(eventImage.imgUrl)}
            </a>
            <div>{moment(eventImage?.createdAt).format("DD.MM.YYYY.")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
