import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { ConnectedProps } from "react-redux";
import { Layout, theme } from "antd";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { NavigationPath } from "../utils";
import connector from "./Container";

const { Content } = Layout;

const PrivateRoute = ({ state, actions }: ConnectedProps<typeof connector>) => {
  useEffect(() => {
    actions.setLoggedUser();
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const token = localStorage.getItem("token");

  return token ? (
    <Layout style={{ minHeight: "100vh" }}>
      <Navbar />

      <Layout>
        <Sidebar />
        <Layout className="p-0 md:p-6 md:pb-0">
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Navigate to={NavigationPath.REGISTER_ROUTE} />
  );
};

export default PrivateRoute;
