import React, { useEffect } from "react";
import { ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";

import AddEditParticipant from "../AddEditParticipant";
import connector from "./Container";

const EditParticipant = ({
  state,
  actions,
}: ConnectedProps<typeof connector>) => {
  const { id } = useParams();

  useEffect(() => {
    id && actions.getParticipantById(id);
  }, []);

  return (
    state.currentParticipant && (
      <AddEditParticipant
        state={state}
        actions={actions}
        isAddParticipant={false}
      />
    )
  );
};

export default EditParticipant;
