import React, { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import {
  Form,
  Input,
  Space,
  Typography,
  Select,
  Button,
  Checkbox,
  Spin,
  notification,
} from "antd";

import {
  AddEditParticipantConnectedProps,
  type PackagePricingType,
  type CompaniesResponse,
  type PrivateRegisterFormValues,
} from "../../../models";
import { roleOptions } from "../../../utils";
import { LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { countryOptions } from "../../../utils/countryOptions";

const AddEditParticipant = ({
  state,
  actions,
  isAddParticipant,
}: AddEditParticipantConnectedProps) => {
  const {
    currentParticipant,
    currentCompany,
    loggedUser,
    packagePricing,
    searchedCompanies,
    loading,
  } = state;
  const [form] = Form.useForm();

  const [isPravnoLice, setIsPravnoLice] = useState<
    boolean | CheckboxChangeEvent
  >(
    !currentParticipant
      ? true
      : currentParticipant.participantType === "Pravno lice"
      ? true
      : false
  );
  const [selectedCompany, setSelectedCompany] = useState<any>("");
  const [isUpdateCompanyDisabled, setIsUpdateCompanyDisabled] = useState(true);

  useEffect(() => {
    actions.getPackagePricing();

    currentParticipant?.Company?.id &&
      actions.getCompanyById(currentParticipant.companyId);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      address: selectedCompany?.address,
      postalCodeAndCity: selectedCompany?.postalCodeAndCity,
      country: selectedCompany?.country,
      companyEmail: selectedCompany?.email,
      phoneNumber: selectedCompany?.phoneNumber,
      ID: selectedCompany?.idNumber,
      PDV: selectedCompany?.vatNumber,
    });
  }, [selectedCompany]);

  useEffect(() => {
    if (!currentParticipant) return;

    if (currentParticipant.participantType === "Pravno lice") {
      setSelectedCompany(currentCompany);
      form.setFieldsValue({
        participantfirstName: currentParticipant.firstName,
        participantlastName: currentParticipant.lastName,
        participantEmail: currentParticipant.email,
        participantPhoneNumber: currentParticipant.phoneNumber,
        role: currentParticipant.role,
        discount: currentParticipant.discount,
        packagePricing: `${currentParticipant.package.type} - ${currentParticipant.package.price} KM`,
      });
    } else {
      form.setFieldsValue({
        participantfirstName: currentParticipant.firstName,
        participantlastName: currentParticipant.lastName,
        participantEmail: currentParticipant.email,
        participantPhoneNumber: currentParticipant.phoneNumber,
        participantAddress: currentParticipant.address,
        participantPostalCodeAndCity: currentParticipant.postalCodeAndCity,
        participantCountry: currentParticipant.country,
        participantCompanyName: currentParticipant.companyName,
        role: currentParticipant.role,
        discount: currentParticipant.discount,
        packagePricing: `${currentParticipant.package.type} - ${currentParticipant.package.price} KM`,
      });
    }
  }, [currentParticipant, currentCompany]);

  const registerPravnoLice = (values: PrivateRegisterFormValues) => {
    const newParticipant = {
      firstName: values.participantfirstName,
      lastName: values.participantlastName,
      email: values.participantEmail,
      phoneNumber: values.participantPhoneNumber
        ? values.participantPhoneNumber
        : null,
      role: values.role,
      packagePricingId: values?.packagePricing,
      discount: values.discount ? values.discount : null,
      status: "Kreiran",
      entityId: loggedUser?.entityId,
      participantType: isPravnoLice ? "Pravno lice" : "Fizičko lice",
      companyId: selectedCompany.id,
      companyName: selectedCompany.name,
      address: values.address,
      postalCodeAndCity: values.postalCodeAndCity,
      country: values.country,
    };

    selectedCompany
      ? actions.registerParticipant(newParticipant)
      : actions.registerCompanyAndParticipant(values, newParticipant);
  };
  const registerFizickoLice = (values: PrivateRegisterFormValues) => {
    const newParticipant = {
      firstName: values.participantfirstName,
      lastName: values.participantlastName,
      email: values.participantEmail,
      phoneNumber: values.participantPhoneNumber
        ? values.participantPhoneNumber
        : null,
      role: values.role,
      packagePricingId: values?.packagePricing,
      discount: values.discount ? values.discount : null,
      status: "Kreiran",
      entityId: loggedUser?.entityId,
      participantType: isPravnoLice ? "Pravno lice" : "Fizičko lice",
      companyName: values.participantCompanyName
        ? values.participantCompanyName
        : null,
      address: values.participantAddress ? values.participantAddress : null,
      postalCodeAndCity: values.participantPostalCodeAndCity,
      country: values.participantCountry,
    };

    actions.registerParticipant(newParticipant);
  };

  const updatePravnoLice = (values: PrivateRegisterFormValues) => {
    const currentPackage = packagePricing.find((pricing) => {
      if (
        pricing.price == currentParticipant?.package.price &&
        pricing.package == currentParticipant?.package.type &&
        pricing.vat == currentParticipant?.package.vat
      ) {
        return pricing;
      }
    });
    const updatedParticipant = {
      firstName: values.participantfirstName,
      lastName: values.participantlastName,
      email: values.participantEmail,
      phoneNumber: values.participantPhoneNumber
        ? values.participantPhoneNumber
        : null,
      role: values.role,
      packagePricingId: currentPackage?.id,
      discount: values.discount ? values.discount : null,
      status: currentParticipant?.status,
      companyId: selectedCompany.id,
      companyName: values.companyName,
      address: values.address,
      postalCodeAndCity: values.postalCodeAndCity,
      country: values.country,
    };

    actions.updateParticipant(updatedParticipant, currentParticipant?.id);
  };

  const updateFizickoLice = (values: PrivateRegisterFormValues) => {
    const currentPackage = packagePricing.find((pricing) => {
      if (
        pricing.price == currentParticipant?.package.price &&
        pricing.package == currentParticipant?.package.type &&
        pricing.vat == currentParticipant?.package.vat
      ) {
        return pricing;
      }
    });

    const updatedParticipant = {
      firstName: values.participantfirstName,
      lastName: values.participantlastName,
      email: values.participantEmail,
      phoneNumber: values.participantPhoneNumber
        ? values.participantPhoneNumber
        : null,
      role: values.role,
      packagePricingId: currentPackage?.id,
      discount: values.discount ? values.discount : null,
      status: currentParticipant?.status,
      companyName: values.participantCompanyName
        ? values.participantCompanyName
        : null,
      address: values.participantAddress ? values.participantAddress : null,
      postalCodeAndCity: values.participantPostalCodeAndCity,
      country: values.participantCountry,
    };

    actions.updateParticipant(updatedParticipant, currentParticipant?.id);
  };

  const updateCompany = () => {
    const values = form.getFieldsValue();

    if (
      !values.companyName ||
      !values.address ||
      !values.postalCodeAndCity ||
      !values.country ||
      !values.companyEmail ||
      !values.phoneNumber ||
      !values.ID
    ) {
      notification.error({
        message: "Unesite obavezna polja!",
      });
      return;
    }

    const updatedCompany = {
      name: values.companyName,
      address: values.address,
      postalCodeAndCity: values.postalCodeAndCity,
      country: values.country,
      email: values.companyEmail,
      phoneNumber: values.phoneNumber,
      idNumber: values.ID,
      vatNumber: values.PDV ? values.PDV : null,
    };

    actions.updateCompany(updatedCompany, currentParticipant?.companyId);
    setIsUpdateCompanyDisabled(true);
  };

  const onFinish = (values: PrivateRegisterFormValues) => {
    if (values.honeypot) return;

    if (isAddParticipant) {
      isPravnoLice ? registerPravnoLice(values) : registerFizickoLice(values);
      return;
    }
    isPravnoLice ? updatePravnoLice(values) : updateFizickoLice(values);
  };

  const onSearch = (value: string) => {
    const MIN_VALUE_LENGTH = 3;
    if (value.length < MIN_VALUE_LENGTH) {
      return;
    }
    !currentParticipant && form.setFieldsValue({ companyName: value });
    actions.getSearchedCompanies(value);
  };

  const onSelectCompany = (value: string) => {
    if (!value) {
      return;
    }
    setSelectedCompany(
      searchedCompanies.find(
        (company: CompaniesResponse) => company.id === value
      )
    );
  };

  const onValuesChange = () => {
    if (isAddParticipant || !currentCompany) return;
    const values = form.getFieldsValue();
    if (
      values.companyName === currentCompany.name &&
      values.address === currentCompany.address &&
      values.postalCodeAndCity === currentCompany.postalCodeAndCity &&
      values.country === currentCompany.country &&
      values.companyEmail === currentCompany.email &&
      values.phoneNumber === currentCompany.phoneNumber &&
      values.ID === currentCompany.idNumber &&
      values.PDV === currentCompany.vatNumber
    ) {
      setIsUpdateCompanyDisabled(true);
      return;
    }

    setIsUpdateCompanyDisabled(false);
  };

  const approveParticipant = () => {
    actions.updateParticipantStatus(currentParticipant?.id, "Odobren");
  };

  const print = () => {
    actions.printTicket(currentParticipant?.id);
  };

  return (
    <Space direction="vertical" className="py-6 pr-4 sm:px-6 w-full  xl:w-4/5">
      <div className="sm:flex justify-between items-center mb-5">
        <Typography className="text-xl font-bold text-dark-blue  ">
          {isAddParticipant ? "REGISTRACIJA NOVOG UČESNIKA" : "UREDI UČESNIKA"}
        </Typography>
        {isAddParticipant ? (
          <Checkbox
            onChange={(e) => setIsPravnoLice(!e.target.checked)}
            className="flex items-center mt-5 sm:mt-0"
          >
            Učesnik je fizičko lice
          </Checkbox>
        ) : (
          //
          <>
            {currentParticipant?.status === "Kreiran" && (
              <Button
                type="primary"
                onClick={approveParticipant}
                className="text-sm text-white bg-custom-gray-700 font-bold rounded-sm w-[132px] border-custom-gray-700 shadow"
              >
                ODOBRI
              </Button>
            )}
            {currentParticipant?.status === "Odobren" && (
              <div
                className={` flex flex-col ${
                  !loading ? "sm:items-end" : "sm:items-center"
                } mt-4 sm:mt-0`}
              >
                <Typography className="text-sm text-white flex justify-center items-center bg-custom-pink-900 font-bold rounded-sm w-[132px] h-8 border border-custom-pink-900 shadow">
                  ODOBREN
                </Typography>
                <Button
                  disabled={loading}
                  type="text"
                  icon={
                    !loading ? (
                      <PrinterOutlined className="text-xl" />
                    ) : (
                      <LoadingOutlined
                        style={{ fontSize: 16, color: "black" }}
                        spin
                      />
                    )
                  }
                  className="text-xs font-extrabold text-edit-blue  flex items-center p-0 mt-2"
                  onClick={print}
                >
                  {!loading ? "PRINTAJ KARTU" : "PRINTANJE"}
                </Button>
              </div>
            )}
            {currentParticipant?.status === "Prisutan" && (
              <Typography className="text-sm text-custom-gray-700 flex justify-center items-center bg-custom-green-900 font-bold rounded-sm w-[132px] h-8 border border-custom-gray-700 shadow">
                PRISUTAN
              </Typography>
            )}
          </>
        )}
      </div>
      <Form
        colon={false}
        layout="vertical"
        form={form}
        name="basic"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div className="hidden">
          <Form.Item name="honeypot">
            <Input type="text" />
          </Form.Item>
        </div>
        {isPravnoLice && (
          <>
            <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mb-2">
              A) PODACI O KOMPANIJI / ORGANIZACIJI
            </div>
            <div className="md:grid grid-cols-2 gap-5">
              <div>
                <Form.Item
                  initialValue={
                    currentParticipant
                      ? currentParticipant?.Company?.name
                      : null
                  }
                  label="Naziv kompanije / organizacije"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Unesite naziv vaše kompanije/organizacije",
                    },
                  ]}
                >
                  <Select
                    placeholder="Unesite naziv vaše kompanije ili organizacije"
                    value={!selectedCompany ? null : selectedCompany}
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onSearch}
                    notFoundContent={null}
                    options={searchedCompanies?.map((company: any) => ({
                      value: company.id,
                      label: company.name,
                    }))}
                    onSelect={onSelectCompany}
                    allowClear={selectedCompany ? true : false}
                    onClear={() => setSelectedCompany("")}
                  />
                </Form.Item>

                <Form.Item
                  label="Adresa"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Unesite adresu vaše kompanije/organizacije",
                    },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Unesite adresu vaše kompanije ili organizacije"
                  />
                </Form.Item>
                <Form.Item
                  label="Poštanski broj i grad"
                  name="postalCodeAndCity"
                  rules={[
                    {
                      required: true,
                      message: "Unesite poštanski broj i grad",
                    },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Unesite poštanski broj i grad"
                  />
                </Form.Item>

                <Form.Item
                  label="Država"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message:
                        "Odaberite državu u kojoj je registrovana kompanija",
                    },
                  ]}
                >
                  <Select
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Odaberite državu u kojoj je registrovana vaša kompanija"
                    options={countryOptions.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="e-mail adresa"
                  name="companyEmail"
                  rules={[
                    {
                      required: true,
                      message: "Unesite email adresu  vaše kompanije",
                    },
                    { type: "email" },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    type="email"
                    placeholder="Unesite email adresu  vaše kompanije ili organizacije"
                  />
                </Form.Item>
                <Form.Item
                  label="Kontakt telefon"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Unesite kontakt telefon  vaše kompanije",
                    },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Unesite kontakt telefon  vaše kompanije ili organizacije"
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="ID broj"
                  name="ID"
                  rules={[
                    {
                      required: true,
                      message: "Unesite ID broj vaše kompanije/organizacije",
                    },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Unesite ID broj vaše kompanije ili organizacije"
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="PDV broj"
                  name="PDV"
                  rules={[
                    {
                      message: "Unesite PDV broj vaše kompanije/organizacije",
                    },
                  ]}
                >
                  <Input
                    disabled={selectedCompany && !currentParticipant}
                    placeholder="Unesite PDV broj vaše kompanije ili organizacije"
                    type="number"
                    min={0}
                  />
                </Form.Item>
              </div>
            </div>

            {currentParticipant && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={updateCompany}
                  disabled={loading || isUpdateCompanyDisabled}
                  className="text-sm font-medium px-5 rounded-sm border-custom-pink-900 shadow mb-6 text-custom-pink-900 bg-white"
                >
                  SPREMI IZMJENE
                </Button>
              </Form.Item>
            )}
          </>
        )}
        <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mb-2">
          {isPravnoLice ? "B" : "A"}) PODACI O UČESNIKU
        </div>
        <div>
          <div>
            <div className="md:grid grid-cols-2 gap-5">
              <div>
                <Form.Item
                  label="Ime učesnika"
                  name="participantfirstName"
                  rules={[
                    {
                      required: true,
                      message: "Unesite ime učesnika",
                    },
                  ]}
                >
                  <Input placeholder="Unesite ime učesnika" />
                </Form.Item>

                <Form.Item
                  label="Prezime učesnika"
                  name="participantlastName"
                  rules={[
                    {
                      required: true,
                      message: "Unesite prezime učesnika",
                    },
                  ]}
                >
                  <Input placeholder="Unesite prezime učesnika" />
                </Form.Item>

                {!isPravnoLice && (
                  <>
                    <Form.Item
                      label="Adresa učesnika"
                      name="participantAddress"
                      rules={[
                        {
                          required: true,
                          message: "Unesite adresu učesnika",
                        },
                      ]}
                    >
                      <Input placeholder="Unesite adresu učesnika" />
                    </Form.Item>

                    <Form.Item
                      label="Poštanski broj i grad"
                      name="participantPostalCodeAndCity"
                      rules={[
                        {
                          required: true,
                          message: "Unesite poštanski broj i grad",
                        },
                      ]}
                    >
                      <Input placeholder="Unesite poštanski broj i grad" />
                    </Form.Item>
                  </>
                )}
              </div>
              <div>
                {!isPravnoLice && (
                  <>
                    <Form.Item
                      label="Država"
                      name="participantCountry"
                      rules={[
                        {
                          required: true,
                          message: "Odaberite državu iz koje dolazite",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Odaberite državu  iz koje dolazite"
                        options={countryOptions.map((item) => ({
                          label: item.label,
                          value: item.value,
                        }))}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label="e-mail adresa učesnika"
                  name="participantEmail"
                  rules={[
                    {
                      required: true,
                      message: "Unesite email adresu učesnika",
                    },
                    { type: "email" },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Unesite email adresu učesnika"
                  />
                </Form.Item>
                <Form.Item
                  label="Kontakt telefon učesnika"
                  name="participantPhoneNumber"
                  rules={[
                    {
                      message: "Unesite kontakt telefon učesnika",
                    },
                  ]}
                >
                  <Input
                    placeholder="Unesite kontakt telefon učesnika"
                    type="number"
                    min={0}
                  />
                </Form.Item>

                {!isPravnoLice && (
                  <>
                    <Form.Item
                      label="Kompanija učesnika (opcionalno)"
                      name="participantCompanyName"
                    >
                      <Input placeholder="Unesite naziv kompanije / organizacije u kojoj ste zaposleni" />
                    </Form.Item>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mb-2">
          {isPravnoLice ? "C" : "B"}) STATUSNI PODACI
        </div>

        <div className="md:grid grid-cols-2 gap-5">
          <div>
            <Form.Item
              label="Uloga"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Odaberite odgovarajuću ulogu za učesnika",
                },
              ]}
            >
              <Select
                placeholder="Odaberite odgovarajuću ulogu za učesnika"
                options={roleOptions.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
              />
            </Form.Item>

            <Form.Item
              label="Paket"
              name="packagePricing"
              rules={[
                {
                  required: true,
                  message: "Odaberite odgovarajući paket",
                },
              ]}
            >
              <Select
                disabled={currentParticipant ? true : false}
                placeholder="Odaberite odgovarajući paket"
                options={packagePricing?.map(
                  (packagePrice: PackagePricingType) => ({
                    value: `${packagePrice?.id}`,
                    label: `${packagePrice?.package} - ${packagePrice?.price} KM`,
                  })
                )}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Popust" name="discount">
              <Input
                placeholder="Unesite popust u procentima (%) "
                type="number"
                min={0}
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className={`text-sm font-medium px-5 rounded-sm border-custom-pink-900 shadow mt-2 ${
                isAddParticipant
                  ? "text-white bg-custom-pink-900"
                  : "text-custom-pink-900 bg-white"
              } `}
            >
              {isAddParticipant ? "REGISTRUJ" : "SPREMI IZMJENE"}
            </Button>
          </Form.Item>

          {loading && (
            <div className="ml-10 mb-2">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 32, color: "black" }}
                    spin
                  />
                }
              />
            </div>
          )}
        </div>
      </Form>
    </Space>
  );
};

export default AddEditParticipant;
