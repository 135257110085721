export function generateOrderNumber() {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let orderNumber = "";
  orderNumber += alphabet.charAt(Math.floor(Math.random() * alphabet.length));

  for (let i = 0; i < 6; i++) {
    orderNumber += Math.floor(Math.random() * 10);
  }
  return orderNumber;
}
