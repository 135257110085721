import React, { useEffect, useState } from "react";
import { Button, Modal, notification, Steps } from "antd";
import { ConnectedProps, useDispatch } from "react-redux";

import cssLogo from "../../images/css-logo.svg";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import connector from "./Container";
import {
  type ParticipantsSignleBasedReqType,
  type PublicRegisterFormValuesType,
  type CompanyAndParticipantsReqType,
  type PackagePricingType,
} from "../../models";
import { IS_SUCCESSFULLY_REGISTERED } from "../Participants/modules/types";
import { generateOrderNumber, sha512 } from "../../utils";
import Footer from "../Footer";

const Register = ({ state, actions }: ConnectedProps<typeof connector>) => {
  const dispatch = useDispatch();

  const {
    packagePricing,
    isLoading,
    isSuccessfullyRegistered,
    isValidData,
    searchedCompanies,
  } = state;
  const [current, setCurrent] = useState(0);
  const [bundle, setBundle] = useState<PackagePricingType | null>(null);
  const [wayOfParticipation, setWayOfParticipation] = useState<string | null>(
    null
  );
  const [paymentInfo, setPaymentInfo] =
    useState<PublicRegisterFormValuesType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [digest, setDigest] = useState<any>(null);
  const [orderNumber, setOrderNumber] = useState<any>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<any>("");

  useEffect(() => {
    if (isValidData && paymentInfo) {
      setIsModalOpen(true);
      generateMonriDigest(paymentInfo);
      return;
    }
    !isValidData && setIsModalOpen(false);
  }, [isValidData]);

  useEffect(() => {
    digest && paymentInfo && paymentCheckout(paymentInfo, bundle);
  }, [digest, paymentInfo]);

  useEffect(() => {
    actions.getPackagePricing();

    let search = window?.location?.search;
    let isSuccess = search.includes("transaction+approved");
    let isDeclined = search.includes("declined");

    isSuccess &&
      dispatch({
        type: IS_SUCCESSFULLY_REGISTERED,
        payload: true,
      });

    isDeclined &&
      notification.error({
        message: "Payment Failed",
      });
  }, []);

  const isStepDisabled = (current: number) => {
    switch (true) {
      case current === 0:
        return false;
      case current === 1:
        return bundle === null;
      case current === 2:
        return bundle === null || wayOfParticipation === null;
    }
  };

  const registerCompanyAndParticipants = (
    values: PublicRegisterFormValuesType
  ) => {
    const companyAndParticipants: CompanyAndParticipantsReqType = {
      entityId: packagePricing[0]?.entityId,
      packagePricingId: bundle?.id,
      sendInvoice: values.payment === "predračun",
      company: !selectedCompany
        ? {
            name: values.companyName,
            address: values.address,
            postalCodeAndCity: values.postalCodeAndCity,
            country: values.country,
            email: values.companyEmail,
            phoneNumber: values.phoneNumber,
            idNumber: values.ID,
            vatNumber: values.PDV ? values.PDV : null,
          }
        : null,
      companyId: selectedCompany?.id ? selectedCompany.id : null,
      participants: values.participants,
    };

    actions.publicRegisterCompanyAndParticipants(companyAndParticipants);
  };

  const registerParticipants = (values: PublicRegisterFormValuesType) => {
    const participants: ParticipantsSignleBasedReqType = {
      entityId: packagePricing[0]?.entityId,
      packagePricingId: bundle?.id,
      sendInvoice: values.payment === "predračun",
      participants: values.participants,
    };

    actions.publicRegisterParticipants(participants);
  };

  const onFinish = (values: PublicRegisterFormValuesType) => {
    if (values.honeypot) return;

    setPaymentMethod(values.payment);
    wayOfParticipation === "PRAVNO LICE"
      ? registerCompanyAndParticipants(values)
      : registerParticipants(values);

    values.payment === "online" && setPaymentInfo(values);
  };

  const registerSteps = () => {
    switch (current) {
      case 0:
        return (
          <StepOne
            packagePricing={packagePricing}
            setBundle={setBundle}
            setCurrent={setCurrent}
          />
        );
      case 1:
        return (
          <StepTwo
            setWayOfParticipation={setWayOfParticipation}
            setCurrent={setCurrent}
          />
        );
      case 2:
        return (
          <StepThree
            wayOfParticipation={wayOfParticipation}
            onFinish={onFinish}
            isLoading={isLoading}
            searchedCompanies={searchedCompanies}
            getSearchedCompanies={actions.getSearchedCompanies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        );
    }
  };

  const generateMonriDigest = (values: PublicRegisterFormValuesType) => {
    const merchantKey = process.env.REACT_APP_MONRI_MERCHANT_KEY;
    const currency = "BAM";
    const orderNumber = generateOrderNumber();

    setOrderNumber(orderNumber);
    const vat = bundle?.vat ? 1.17 : 1;
    const quantity = values.participants.length;

    const amountInMinorUnits = Math.round(
      Number(bundle?.price) * 100 * vat * quantity
    );

    const dataToHash =
      merchantKey + orderNumber + amountInMinorUnits + currency;
    const hash = sha512(dataToHash).then((x) => setDigest(x));
  };

  const paymentCheckout = (
    values: PublicRegisterFormValuesType,
    bundle: any
  ) => {
    const vat = bundle?.vat ? 1.17 : 1;
    const quantity = values.participants.length;
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://ipgtest.monri.com/dist/lightbox.js";
    scriptElement.className = "lightbox-button";
    scriptElement.setAttribute(
      "data-authenticity-token",
      `${process.env.REACT_APP_MONRI_AUTHENTICITY_TOKEN}`
    );
    scriptElement.setAttribute(
      "data-amount",
      `${Math.round(Number(bundle?.price) * 100 * vat * quantity)}`
    );
    scriptElement.setAttribute("data-currency", "BAM");
    scriptElement.setAttribute(
      "data-ch-full-name",
      `${values.participants[0].firstName} ${values.participants[0].lastName}`
    );
    scriptElement.setAttribute(
      "data-ch-city",
      values.participants[0].postalCodeAndCity?.split(" ")[1]
        ? values.participants[0].postalCodeAndCity
            ?.split(" ")
            .slice(1)
            .join(" ")
        : ""
    );
    scriptElement.setAttribute(
      "data-ch-zip",
      values.participants[0].postalCodeAndCity
        ? values.participants[0].postalCodeAndCity.split(" ")[0]
        : ""
    );
    scriptElement.setAttribute(
      "data-ch-address",
      values.participants[0].address ? values.participants[0].address : ""
    );
    scriptElement.setAttribute(
      "data-ch-country",
      values.participants[0].country ? values.participants[0].country : ""
    );
    scriptElement.setAttribute("data-order-info", "Purchase");
    scriptElement.setAttribute("data-digest", digest);
    scriptElement.setAttribute("data-language", "en");
    scriptElement.setAttribute("data-order-number", orderNumber);
    scriptElement.setAttribute("data-transaction-type", "purchase");
    scriptElement.setAttribute(
      "data-ch-phone",
      values.participants[0].phoneNumber
        ? values.participants[0].phoneNumber
        : ""
    );
    scriptElement.setAttribute(
      "data-ch-email",
      values.participants[0].email ? values.participants[0].email : ""
    );

    scriptElement.setAttribute(
      "data-custom-params",
      wayOfParticipation === "PRAVNO LICE"
        ? JSON.stringify({
            entityId: packagePricing[0]?.entityId,
            packagePricingId: bundle?.id,
            sendInvoice: values.payment === "predračun",
            orderNumber: orderNumber,
            company: !selectedCompany
              ? {
                  name: values.companyName,
                  address: values.address,
                  postalCodeAndCity: values.postalCodeAndCity,
                  country: values.country,
                  email: values.companyEmail,
                  phoneNumber: values.phoneNumber,
                  idNumber: values.ID,
                  vatNumber: values.PDV ? values.PDV : null,
                }
              : null,
            companyId: selectedCompany?.id ? selectedCompany.id : null,
            participants: values.participants,
          })
        : JSON.stringify({
            entityId: packagePricing[0]?.entityId,
            packagePricingId: bundle?.id,
            sendInvoice: values.payment === "predračun",
            orderNumber: orderNumber,
            participants: values.participants,
          })
    );

    const formElement = document.createElement("form");
    formElement.appendChild(scriptElement);
    document.getElementById("monri-button-container")!.innerHTML = "";
    document.getElementById("monri-button-container")!.appendChild(formElement);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <div className="flex justify-center border-b-2 border-custom-pink-900 h-[80px] 2xl:h-auto">
          <img src={cssLogo} alt="cssLogo" className="py-2" />
        </div>
        {!isSuccessfullyRegistered ? (
          <>
            <div>
              <div className="pt-8 text-center text-custom-gray-800 text-[30px] sm:text-[38px] 2xl:text-[48px] font-lato font-black">
                Registracija
              </div>
              <div className="md:w-[80%] lg:w-[60%] m-auto ">
                <Steps
                  responsive={false}
                  type="navigation"
                  size="small"
                  current={current}
                  onChange={(value) => setCurrent(value)}
                  className="site-navigation-steps"
                  items={[
                    {
                      title: "Korak 1",
                      disabled: isStepDisabled(0),
                      status:
                        current === 0 ? "process" : bundle ? "finish" : "wait",
                    },
                    {
                      title: "Korak 2",
                      disabled: isStepDisabled(1),

                      status:
                        current === 1
                          ? "process"
                          : wayOfParticipation
                          ? "finish"
                          : "wait",
                    },
                    {
                      title: "Korak 3",
                      disabled: isStepDisabled(2),
                    },
                  ]}
                />
              </div>
            </div>
            <div>{registerSteps()}</div>
          </>
        ) : (
          <div className="flex justify-center text-center mt-6 sm:mt-20">
            <div className="flex flex-col gap-6">
              <div className="text-custom-gray-700 text-[22px] leading-[30px] md:text-[34px] md:leading-[42px] lg:text-[40px] lg:leading-[48px] 2xl:text-[48px] 2xl:leading-[58px] font-bold">
                Hvala vam što ste odlučili da
                <div> nam ukažete povjerenje!</div>
              </div>
              <div className="text-custom-gray-700  w-[95%] sm:w-[85%] m-auto text-[11px] md:text-[14px]  lg:text-[18px]  2xl:text-[22px] ">
                {paymentMethod === "predračun"
                  ? "Predračun je kreiran i poslan na email adresu navedenu prilikom registracije."
                  : "Svi registrovani učesnici su na navedene e-mail adrese dobili ulaznice za konferenciju."}
              </div>
              <div>
                <Button
                  htmlType="button"
                  className="text-white bg-custom-pink-900 border border-custom-pink-900  sm:w-[210px] h-10 sm:text-[16px] font-bold  rounded-[2px] shadow"
                >
                  <a href="https://yes-css.ba/">NAZAD NA POČETNU</a>
                </Button>
              </div>
            </div>
          </div>
        )}
        <Modal
          title="Idi na plaćanje"
          className="text-center mt-32"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div id="monri-button-container"></div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
