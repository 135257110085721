import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";

import connector from "./Container";
import { NavigationPath, sidebarMenuItems } from "../../utils";
import { ConnectedProps } from "react-redux";

const { Sider } = Layout;

const Sidebar = ({ state }: ConnectedProps<typeof connector>) => {
  const { loggedUser } = state;
  const navigate = useNavigate();
  const location = window.location.pathname;
  const [selected, setSelected] = useState("/");
  const [items, setItems] = useState(sidebarMenuItems);

  useEffect(() => {
    setSelected(location);
  }, [location]);

  useEffect(() => {
    if (loggedUser?.role === "Staff") {
      setItems(
        items?.filter((item) => item?.key !== NavigationPath.DASHBOARD_ROUTE)
      );
    }
  }, [loggedUser]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return !location.includes("/welcome") ? (
    <Sider
      breakpoint="lg"
      collapsedWidth="40"
      className="md:mt-6"
      style={{ background: colorBgContainer }}
    >
      <Menu
        onClick={(item) => navigate(item.key)}
        selectedKeys={[selected]}
        className="mt-3 border-r-0"
        mode="inline"
        items={items}
      />
    </Sider>
  ) : (
    <div></div>
  );
};

export default Sidebar;
