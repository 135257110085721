export const NavigationPath = {
  REGISTER_ROUTE: "/register",
  LOGIN_ROUTE: "/login",
  RESET_PASSWORD_ROUTE: "/reset-password",
  DASHBOARD_ROUTE: "/dashboard",
  PARTICIPANTS_ROUTE: "/participants",
  ADD_PARTICIPANT_ROUTE: "/add-participant",
  EDIT_PARTICIPANT_ROUTE: "/participant/:id",
  AFTER_SCAN_ROUTE: "/welcome/:id",
  IMPRESSUM: "/impressum",
  PAYMENT_SECURITY: "/payment-security",
  PRIVACY_STATEMENT: "/privacy-statement",
  PURCHASE_TERMS: "/purchase-terms",
};
