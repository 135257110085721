import { Action, Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import { notification } from "antd";

import {
  ApiService,
  AuthorizedApiService,
} from "../../../services/apiServices";
import { type LoginFieldType } from "../../../models";
import { SET_LOADING, SET_LOGGED_USER } from "./types";
import { NavigationPath } from "../../../utils";

const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: loading,
});

export const loginUser =
  (user: LoginFieldType) => async (dispatch: Dispatch<Action>) => {
    try {
      ApiService.post("auth/login", user)
        .then((res: AxiosResponse) => {
          const { status, data } = res;
          if (status !== 200) {
            notification.error({
              message: "Invalid Login!",
            });
            return;
          }

          localStorage.setItem("token", data.token);
          localStorage.setItem("currentUser", JSON.stringify(data.user));
          notification.success({
            message: data.message,
          });
          data.user.role === "Admin"
            ? window.location.assign(NavigationPath.DASHBOARD_ROUTE)
            : window.location.assign(NavigationPath.PARTICIPANTS_ROUTE);
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Invalid Login!",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const setLoggedUser = () => async (dispatch: Dispatch<Action>) => {
  const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
  if (user) {
    dispatch({
      type: SET_LOGGED_USER,
      payload: user,
    });
  }
};

export const logoutUser = () => async (dispatch: Dispatch<Action>) => {
  localStorage.removeItem("token");
  localStorage.removeItem("currentUser");
  dispatch({
    type: SET_LOGGED_USER,
    payload: null,
  });
  notification.success({
    message: "Logout success!",
  });
};

export const sendResetPasswordEmail =
  (email: string) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(setLoading(true));

      AuthorizedApiService.post("auth/send-reset-password-email", { email })
        .then((res: any) => {
          const { status } = res;

          if (status !== 200) {
            if (
              (res.response.data.message = "User with that email not found")
            ) {
              notification.error({
                message: "User with that email is not found!",
              });
              dispatch(setLoading(false));
              return;
            }
            notification.error({
              message: "Something went wrong!",
            });
            dispatch(setLoading(false));
            return;
          }

          notification.success({
            message: `Email sent to ${email}`,
          });
          dispatch(setLoading(false));
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
          dispatch(setLoading(false));
        });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

export const resetPassword =
  (email: string | undefined, password: string, token: string) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      AuthorizedApiService.post("auth/reset-password", {
        email,
        password,
        token,
      })
        .then((res: any) => {
          const { status, data } = res;

          if (status !== 200) {
            if (
              res.response.data.message === "Token Expired" ||
              res.response.data.message === "Token Invalid"
            ) {
              notification.error({
                message: res.response.data.message,
              });
              return;
            }
            notification.error({
              message: "Something went wrong!",
            });
            return;
          }

          notification.success({
            message: "Reset password success!",
          });
          window.location.assign(NavigationPath.LOGIN_ROUTE);
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
